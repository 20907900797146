import React,{useState} from 'react'
import { capitalizeFirstLetter,getTotalPax,QUOTE_INPUT_LABEL,INPUT_DATA,getOrdinal } from '../../constants'
import BookingPersonV1 from '../Transfer/BookingPersonV1';
import {getBeforeUnderscore,generateIdIndexBase} from "../../utility/globalFunc";
import { InputBox,FlightNumberBox } from './Atom';


const temp = [ {
    type:'adult',
    title: '',
    last_name: '',
    age: 0,
    status:'',
    honeymoon:'',
    hotel_id: -1,
    language: '',
    first_name: '',
    sur_name: '',
    remarks: '',
    room_detail_id: -1, 
    id:  1,
  }]

function BookingInputs({ errorInput, seterrorInput,refCode,first_name,last_name,handleChange,formState,searchOnPaxDone=false,roomPax,isPaxSelected,serviceRerservation,optionData,setOptionData}) {

    const [focusedElements, setFocusedElements] = useState([]);

    const handleFocused = (newItem) =>{
        setFocusedElements((prevItems) => {
            if (!prevItems.includes(newItem)) {
                return [...prevItems, newItem];
            }
            return prevItems; 
        });
    }

    function restoreStatusInput(){
        seterrorInput({ indiceError: [false, false], statusStyle: ['success', 'success'] });
    }

    function removeFocus(e){
        restoreStatusInput();
        const inputElement = e.target;
        if (!inputElement.value) {
            inputElement.classList.remove('success');
            inputElement.classList.add('error');
        }
    }

    function detectChange(e) {
       if(errorInput){
           const inputElement = e.target;
           if (inputElement.classList.contains('error')) {
               inputElement.classList.remove('error');
           }
           if(inputElement.value != " "){
               inputElement.classList.add('success');
           }
       }
    }

    const getAge=(pax)=>{
        return pax.type!=="adult"?"("+pax.age+" yo)":"";
    }

    const renderForm = (formState) =>{
        let typeNumber={};
        const incrementTypeNumber=(type)=>{
            if (typeNumber.hasOwnProperty(type)) {
                typeNumber[type]+=1
            }else{
                typeNumber[type]=1;
            }
        }
        return (
            formState?.map((item, index) => {
            incrementTypeNumber(item.type);
            return (
                <div key={index} className='quote_inputs'>
                    <h5 className='quote_inputs_h5'> {capitalizeFirstLetter(item.type)} No. {item.numero||typeNumber[item.type]} {getAge(item)}</h5>
                    
                    <div className='quote_inputboxes'>
                        <div className={`quote_inputboxes_first_name`}> 
                            <input onBlur={(event) => removeFocus(event)} className={`${errorInput && index === 0 ? errorInput.statusStyle[0] : ""}`} type="text" value={item.first_name || ''} onFocus={() => { handleFocused('firstname' + index) }} onChange={(e) => { handleChange(index, 'first_name', e.target.value); detectChange(e) }} placeholder='Please insert client’s first name'/>
                           {!focusedElements.includes('firstname'+index) &&index==0 &&<span className='quote_inputboxes_first_name_star'>*</span>} 
                        </div>
                        <div className={`quote_inputboxes_surname`}>  
                            <input onBlur={(event)=>removeFocus(event)} className={`${errorInput && index === 0 ? errorInput.statusStyle[1] : ""}`}  type="text" value={item.sur_name  || ''} onFocus={() =>{handleFocused('sur_name'+index)}}  onChange={(e) => {handleChange(index, 'sur_name', e.target.value) ;detectChange(e)}} placeholder='Please insert client’s surname'/>

                            {!focusedElements.includes('sur_name' + index) && index == 0 && <span className='quote_inputboxes_surname_star'>*</span>}
                        </div>
                        {errorInput && index === 0 && errorInput.indiceError.some(error => error === true) && (
                            <div className={`toast-error-form ${errorInput ? 'show' : ''}`}>
                                <span>Please input client’s name before going further in your reservation</span>
                            </div>
                        )}

                        <textarea value={item.remarks  || ''}  onChange={(e) => handleChange(index, 'remarks', e.target.value)} placeholder='Do you have any remarks ?' ></textarea> 
                    </div>
                </div>
                )})
        )
    }




    return (
        <div className='quote_input_parent'>
            <div className='quote_input_left'>
                <div className='quote_left_head'>
                    <p className='quote_left_headtxt0'>{getBeforeUnderscore (refCode)}</p>
                    <div className='quote_left_headtxt1'>Booking n° {getBeforeUnderscore(refCode)}</div>
                    <div className='quote_left_headtxt2'>{first_name}  {last_name}</div>
                    <div className='quote_left_mandatory'>Mandatory fields* </div>
                </div>
                <>
                    <div className='quote_section_title marginBottom-lvl1'>{QUOTE_INPUT_LABEL[0]}</div>
                    <div className='quote_input_flight'>
                       {
                            serviceRerservation.reservationTransfer.length &&
                            generateIdIndexBase(serviceRerservation.reservationTransfer,"IdTransfer")
                                .filter((data) => data.LocationFromId === 752 || data.LocationToId === 752) // Filter condition
                                .map((data, index) => {
                                return (
                                    <FlightNumberBox key={data.IdTransfer}>
                                    <div className="quote_input_flight_title">
                                        {data.IdTransfer}
                                        {getOrdinal(data.IdTransfer)} Transfer
                                    </div>
                                    <div>
                                        {data.LocationFromName} &rarr; {data.LocationToName}
                                    </div>
                                    <div>{data.TransferDate}</div>
                                    <InputBox data={INPUT_DATA} classname="flight-input" />
                                    </FlightNumberBox>
                                );
                                })
                            }

                    </div>
                </>

                <div className='quote_input_paxchoice'>
                    {/* <div>{JSON.stringify(roomPax)}</div> */}
                <h5 className='quote_section_title marginBottom-lvl2'>{QUOTE_INPUT_LABEL[1]}<span className = "stars" style={{color:"#FFA75D"}}>*</span></h5>
                    <BookingPersonV1 
                        cname={7} 
                        paxInfo={roomPax} 
                        setLablel={'0 Adult'} 
                        serviceRerservation={serviceRerservation}
                        optionData={optionData}
                        setOptionData={setOptionData} />
                </div>
                <div className={`quote_input_paxform ${isPaxSelected ? '' : 'disabled'}`}>
                    <h5 className='quote_section_title' style={{marginBottom:"20px"}}>{QUOTE_INPUT_LABEL[2]}</h5>
                    <form>
                        { 
                            getTotalPax(roomPax[0]) > 0 ? renderForm(formState) : renderForm(temp)
                            // getTotalPax(transferPax[0]) > 0 ? renderForm(formState) : renderForm(temp)
                        }
                    </form>
                </div>
            
            </div>   
        </div>
    )
}

export default BookingInputs