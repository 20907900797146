import { createSlice } from '@reduxjs/toolkit'



const INITIAL_STATE = {
  excursionList:null,
  filters:null,
  calendarDate:[],
  quotation:false,
  selExcursion:[],
  excursionTotal:null,
  selDate:"",
  paxData:[{"adult":1,"teen":0,"child":0,"infant":0,"room_id":1,"paxAge":[],clientType:["Standard","STD"]}],
}

export const excursionData = createSlice({
  name: 'excursionreducer',
  initialState:INITIAL_STATE,
  reducers: {
        excursionDates(state, action) {
            state.calendarDate = action.payload;
        },
        clearDates(state, action) {
          state.calendarDate = []      
        },
        selExcursionDate(state,action){
          state.selDate = action.payload
        },
        clearSelDate(state,action){
          state.selDate = ""
        },
        listExcursion(state, action) {
            let {lists,options}=action.payload
            state.excursionList = lists
            state.filters = options
        },
        updateLists(state, action) {
          let {lists}=action.payload
          state.excursionList = lists
        },
        aboutExcursion(state, action) {
          console.log("action.payload",action.payload);
          state.selExcursion = [...state.selExcursion,action.payload]
        },
        removeSelection(state, action) {
          let update = state.selExcursion.filter(data => data.excursion_id !== action.payload)
          state.selExcursion = update
        },
        quotationPopup(state, action) {
          state.quotation = action.payload
        },
        clearexcursion(state, action){
          return INITIAL_STATE;
        },
        excurPax(state,action){
          state.paxData  = action.payload;
        }
      

  },
})

export const {excurPax,clearexcursion,selExcursionDate,clearSelDate,removeSelection,updateLists,excursionDates,listExcursion,clearDates,quotationPopup,aboutExcursion } = excursionData.actions

export default excursionData