import React, { useState,useEffect,useCallback } from 'react';
import {useDispatch,useSelector } from 'react-redux';
import {removeSearch} from "../store/errorReducer.js";
import {SaveCotationInputs} from "../components/Transfer/SaveCotation.js"
import RoomPopup from '../components/MiniComponents/RoomPopup.js';
import {CancellationPolicyPopup, GlobalAmenities} from '../components/MiniComponents/Atom.js';
import DialogueBox from "../components/MiniComponents/DialogueBox.js"
import TokenExpiredPopup from '../components/Accomodation/minicomponents/TokenExpiredPopup.js';
import QuotationUpdatedPopUp from '../components/Transfer/QuotationUpdatedPopUp.js';
import {QuoteDeletePopup,QuoteDeleteService,ExportPdfPopup,QuotationSendMail,CancelUpdatePopup} from '../components/MiniComponents/CancelUpdatePopup.js';
import AgencyListPopup from '../components/Transfer/AgencyListPopup.js';
import AddServiceBox from '../components/MiniComponents/AddServiceBox.js';
import BookingConfirmed from '../components/Excursion/BookingConfirmed.js';
import RemoveItemBooking from '../components/Excursion/RemoveItemBooking.js';
import CancelBooking from '../components/MiniComponents/CancelBooking.js';
import RoomOfferPopup from '../components/Accomodation/RoomOfferPopup.js';

export const TOKEN_EXPIRED_POPUP="TOKEN_EXPIRED_POPUP";
export const CANCEL_UPDATE_POPUP="CANCEL_UPDATE_POPUP";
export const GLOBAL_AMENITIES_POPUP="GLOBAL_AMENITIES_POPUP";
export const DELETE_QUOTE="DELETE_QUOTE_POPUP";
export const REMOVE_SERVICE_ITEMS = "REMOVE_ITEMS";
export const EXPORT_PDF_POPUP = "EXPORT_PDF";
export const SEND_MAIL ="SEND_MAIL"
export const ADD_SERVICE_BOOKING = "ADD_SERVICE_BOOKING"
export const BOOKING_REQUESTED = "BOOKING_REQUESTED"
export const CANCEL_BOOKING = "CANCEL_BOOKING"
export const ROOM_OFFERS_POPUP = "ROOM_OFFERS_POPUP"

// Custom hook to manage popup state
function usePopupData() {
    const dispatch = useDispatch();
    const [popupType, setPopupType] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [messagepop, setMessagepop] = useState(null);
    const [keyName,setKeyName]=useState(null)

    useEffect(() => {
        if(popupType === "del-message" || popupType === "select-vehicle"){
            const timeoutId = setTimeout(() => {
                setIsOpen(false)
                setPopupType(null)
              }, 2000); 
        
              return () => {
                clearTimeout(timeoutId);
            };
        }
        
    }, [isOpen]);
    
    const openPopup = useCallback((type, messages, name,) => {
        setPopupType(type);
        setIsOpen(true);
        setMessagepop(messages);
        setKeyName(name);
    }, []);  // Empty dependency array means this function won't be recreated on re-renders

    const closePopup = useCallback(() => {
        setIsOpen(false);
        setPopupType(null);
        setKeyName(null);
        setMessagepop(null)
        dispatch(removeSearch({name:keyName}))
    }, []);

    const openNew = (e,name) => {
        e.preventDefault()
        setPopupType(name);
        setIsOpen(true);
    };


    
    // Function to get the content of the popup based on the type 
    const getPopupContent = (data) => {
        
        switch (popupType) {
            case 'info':
                return(
                <div className='deletepopup_container'>
                    <SaveCotationInputs closepopup={closePopup} phase={2} />
                </div>)

            case 'info-not-saved':
                return(
                <div className='deletepopup_container'>
                    <SaveCotationInputs closepopup={closePopup} phase={2} notsaved={true}/>
                </div>)

            case 'updated-quotation':
                return(
                <div className='deletepopup_container'>
                    <QuotationUpdatedPopUp data={messagepop} />
                </div>)
             
             case 'agency-list':
                return(
                <div className='agency-popup-background'>
                    <AgencyListPopup closepopup={closePopup} />
                </div>) 

            case 'room-popup':
                return(
                <div className='deletepopup_container'>
                    <RoomPopup closepopup={closePopup}/>
                </div>)  
            case 'booking-done':
                return(
                <div className='deletepopup_container'>
                    <BookingDonePopup closepopup={closePopup}/>
                </div>) 
            case 'cancellation-Policy':
                return(
                <div className='deletepopup_container'>
                    <CancellationPolicyPopup closepopup={closePopup}  data={data}/>
                </div>)    
            case 'error':
                return (
                <div className='deletepopup_container'>
                    <div className='errorBox'>
                       <b>Message: </b>{messagepop}
                       <div style={{marginTop:"20px"}}><button className="popbtn" onClick={(e)=>{closePopup()}}>Close</button></div>
                    </div>
                </div>
                );
            case 'del-message':   
                return <div className='success-delete'><p>Transfer line has been delete 👍</p></div>
            case 'select-vehicle':   
                return <div className='vehicle-fill-pop'><p>Please select all vehicles of your transfer list ! 🙅🏽‍♂️</p></div>      
            case 'select-room':   
                return <div className='hotel-fill-pop'><p>Please select all the rooms in your hotel ! 🙅🏽‍♂️</p></div>      
            case 'dialogue-box':   
                return (
                    <div className='deletepopup_container'>
                        <DialogueBox closePopup={closePopup} openNew={openNew} toPath={keyName}/>
                    </div>
                    );   

            case TOKEN_EXPIRED_POPUP:
                return (
                <div className='deletepopup_container'>
                    <TokenExpiredPopup closePopup={closePopup}/>
                </div>);  
            case CANCEL_UPDATE_POPUP:
                return (
                <div className='deletepopup_container'>
                    <CancelUpdatePopup closePopup={closePopup}/>
                </div>);
            case GLOBAL_AMENITIES_POPUP:
                return (
                    <div className='deletepopup_container'>
                        <GlobalAmenities isPopup={true} amenities={data.amenities} closePopup={data.closePopup}/>
                    </div>
                );
            
            case DELETE_QUOTE:
                return (
                    <div className='deletepopup_container'>
                        <QuoteDeletePopup name={keyName} closePopup={closePopup}/>
                    </div>
                );   
            case REMOVE_SERVICE_ITEMS:
                return (
                    <div className='deletepopup_container'>
                        <QuoteDeleteService data={keyName} closePopup={closePopup}/>
                    </div>
                );        

            case EXPORT_PDF_POPUP:    
                return (
                    <div className='deletepopup_container'>
                        <ExportPdfPopup closePopup={closePopup}/>
                    </div>
                ); 
            case SEND_MAIL:    
                return (
                    <div className='deletepopup_container'>
                        <QuotationSendMail closePopup={closePopup}/>
                    </div>
                ); 
            

            case ADD_SERVICE_BOOKING:
                return (
                    <div className='deletepopup_container'>
                        <AddServiceBox closePopup={closePopup} openNew={openNew} toPath={"/select-vehicule"}/>
                    </div>
                );        
            case BOOKING_REQUESTED:
                return (
                    <div className='deletepopup_container'>
                        <BookingConfirmed name={keyName} closePopup={closePopup}/>
                        {/* <AddServiceBox closePopup={closePopup} openNew={openNew} toPath={"/select-vehicule"}/> */}
                    </div>
                );        
            case CANCEL_BOOKING:
                return (
                    <div className='deletepopup_container'>
                        <CancelBooking closePopup={closePopup} booking={data} openNew={openNew} toPath={"/select-vehicule"}/>
                    </div>
                );    
            
            case ROOM_OFFERS_POPUP:
                return (
                    <div className='deletepopup_container'>
                        <RoomOfferPopup closePopup={closePopup} roomImg={data.roomImage} roomName={data.roomName} pax={data.pax} mealPlan={data.mealPlan} amenities={data.amenities}/>
                    </div>
                );
            default:
                return null;
        }
    };
   
    return {
        isOpen,
        openPopup,
        closePopup,
        getPopupContent,
        setIsOpen,
        popupType
    };
}

export default usePopupData;
