import React,{useState,useRef, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {getOrdinal} from "../../constants";
import DisplayCar from "../Transfer/DisplayCar.js"
import ListTransfer from './ListTransfer.js';
import { Skeleton } from '@mui/material';
import { trigerId } from '../../store/transferReducer.js';
// import { info } from "../../../Assets/images/global-icon/info-red.svg";
import  info  from "../../Assets/images/global-icon/info.svg";
import { BackButtonHandler } from '../MiniComponents/Atom.js';
function SelectCars() {
    const dispatch = useDispatch();
    const { tempId: getId,isResultSameVehicle } = useSelector((state) => state.transfer_Global);
    const { visitedTransferList: isVisited } = useSelector((state) => state.transfer_Global);
    const compBRef = useRef(null);
    const {transferList,transferUpdates,regionDetails,updatePax,loading} =useSelector((state) => state.transfer_Global)    

    // let gridStructure = {"gridTemplateColumns":"286px 421px 44px 421px 28px","display":"grid","position":"relative"}
  let gridStructure = { "gridTemplateColumns":"223px 256px 300px 50px 300px","display":"grid","position":"relative"}
    let result1 = transferList.map(({price,Car_selected,currency,pic_url,vehicle_type,id_transfer_cat,id_vehicle_type,noOfCars,capacity,baby_seat_count,LuggageTruck,LuggageCar,...rest}) => ({...rest}));
    let result2 = transferUpdates.map(({price,Car_selected,currency,pic_url,vehicle_type,capacity,...rest}) => ({...rest}));
    
    let equal =  JSON.stringify(result2) === JSON.stringify(result1)



  const getColorButton = (item, ind) => {
    if (item.Car_selected=== false) {
      if (getId === ind){
        return "transfer_to_fill_focus";
      }
      else{
        return "transfer_enable"
      }
    }
    else{
      if (getId === ind) {
        return "transfer_fill_focus";
      }
      else if (getId !== ind) {
        return "transfer_fill";
      }
    }

  }

    const tClick = (e, selectedId, region) => {
      // if (getId <= selectedId && !transferList[selectedId-1].Car_selected){
      //   return;
      // }
      const current = getId;
      if (transferList[selectedId].Car_selected||selectedId<=getId) {
        dispatch(trigerId(selectedId));
        return;
      }

      if (transferList[current].Car_selected){
        dispatch(trigerId(selectedId));
        return;
      } 
   };   

  return (
    <div className="carDisplayContainer" ref={compBRef}>
      {!loading ? <div className="pickCarsText" id='pickCarsText'>
      {isVisited? 
          <div className='visitedTransfer'>
            <p>Vehicle list</p>
            <div className='containerInfo'>
              <img src={info} alt="info" />
              <span className='infoVehiculeList' >Caution : Please input transfer date in order to get more details on vehicules</span>
            </div>
          </div>
        :<p>Select a vehicle</p>
      }
    </div>: <Skeleton width={120} style={{marginBottom:"20px"}}/>}
      <div className='pickCar_parentloop'>
        {transferList.length > 1 && !isResultSameVehicle && (
          !isVisited ?
            (!loading ? (
              transferList.map((data, ind) => {
                return (
                  <div key={ind} id={"chk" + ind}
                    className={"carMapParent " + getColorButton(data,ind)}
                    style={{ opacity: getId === ind ? "1" : "0.5" }}
                  >
                    <div onClick={(e) => tClick(e, ind, data)}>
                      {ind + 1}{getOrdinal(ind + 1)} Transfer
                    </div>
                  </div>
                );
              })
            ) : (
              <Skeleton width={120} style={{ marginBottom: "20px" }} />
            ))
            : null
        )}

      </div>

      <div style={{width:"100%",float:"left"}}>
          {/* //if visited */}
        {!isVisited && !isResultSameVehicle? 
          <ListTransfer
            lists={[transferList[getId]]}
            styles={gridStructure}
            noClick={true}
            addArrow={true}
          />:null
        }
      </div> 

      <div style={{ marginTop: "50px", width: "100%", float: "left" }} 
        className={isVisited ? 'disable' : ''}
      >
        {!isVisited ? 
          <DisplayCar
            passId={getId}
            userData={transferList}
            noClick={true}
            position={compBRef.current !== null ? compBRef.current.offsetTop : 0}
          />
          :
          <DisplayCar
            passId={0}
            userData={transferList}
            noClick={equal}
            position={compBRef.current !== null ? compBRef.current.offsetTop : 0}
          />
        }
      
      <BackButtonHandler service={transferList} serviceName={'Transfer'}  />
      </div>
    </div>
  );

}

export default SelectCars;