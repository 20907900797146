import React,{useState,useEffect,useRef} from 'react';
import axios from "axios";
import img1 from "../../Assets/images/location-pin5.png";
import gray_airplane from "../../Assets/images/gray-plane.png"
import { SelectedLocation } from "../Transfer/SaveCotation.js";
import Skeleton from '@mui/material/Skeleton';
import { useSelector,useDispatch } from 'react-redux';
import {locationUpdates,removeLocation} from "../../store/transferReducer.js"
import { useNavigate } from 'react-router-dom';
import B2BSkeleton from '../Accomodation/minicomponents/B2BSkeleton.js';
import ROOMS_SKELETON from "../../Assets/images/skeleton/transfert-destination-skeleton.png";
import { LOCPOINT_ENDPOINT } from '../../services/apiEndpoints.js';
import { properCase } from '../../utility/globalFunc.js';
import useGetHotels from '../../hooks/useGetHotels.js';
import { AIRPORT_LOCATION_NAME, capitalizeFirstLetter } from '../../constants.js';

function LocPointsTransfer({
  label,
  inpText,
  inpTextDetail,
  points,
  passArray1,
  passArray2,
  passId,
  // staticType=false,
  rowid,
  errmsg,
  scroll,
  locationPoints
}) {


    let token = localStorage.getItem("access-token")
    const inpref = useRef(null);
    const {transferUpdates,loading:apiload}=useSelector((state) => state.transfer_Global)
    const dispatch = useDispatch();
    const [popupOpen, setPopupOpen] = useState({dropdown: false,elementHide: true,value:false});
    const [friendlyZone, setFriendlyZone] = useState(false) 
    const [loading, setLoading] = useState(true) 
    const [sameLocation ,setSameLocation]=useState(false)
    const [optionData, setoptionData] = useState([]); 
    const [optionRecords, setoptionRecords] = useState([]); //[]
    const navigate = useNavigate();


  useEffect(() => {
    if(popupOpen.elementHide===false){
      setFriendlyZone(false)
      inpref.current.focus();
      if(scroll)
        window.scrollTo({
          top: document.documentElement.scrollHeight,
          behavior: 'smooth',
          });
      else{
        
      }    
    }
    
  }, [popupOpen]);
  
  // callingh api
  // const { hotelData, loading: getload, fetchData } = useGetHotels();
  // useEffect(() => {
  //   !hotelData && fetchData()
  // }, [])
  useEffect(() => { 
    locationPoints && (setoptionData(locationPoints), setoptionRecords(locationPoints)); 
  }, [locationPoints]);
  
  
  useEffect(() => {
    setoptionData(locationPoints);
    setoptionRecords(locationPoints);
  }, [locationPoints])
  

  const [searchMode, setsearchMode] = useState(false);
  // refa click input presentation
  const handleDropdown = (e, value) => {
    // input logic section 
    setoptionData(locationPoints);
    setoptionRecords(locationPoints);
    setsearchMode(true);
    inpref.current?.focus();
    inpref.current.value = '';

    e.preventDefault();
    setPopupOpen((prevstate)=>({
      ...prevstate,
      elementHide: !popupOpen.elementHide,
      value:true
    }))
  };
  
  //// Select Options values and display.........................
  const optionValue = (e, name, details, point, region, location) => {
    e.preventDefault();
    // Fonction générique pour gérer les mises à jour de localisation
    const handleLocationUpdate = (pointType, comparisonName) => {
      let payload = { id: rowid, name, details, region, location, point: pointType };

      if (transferUpdates[rowid][comparisonName] !== name) {
        dispatch(locationUpdates(payload));
        setPopupOpen((prevState) => ({
          ...prevState,
          elementHide: !popupOpen.elementHide,
        }));
       
      } else {
        setSameLocation(true);
      }
    };

    // Appel de la fonction pour chaque point
    if (point === "pickup") {
      handleLocationUpdate("pickup", "LocationToName");
    } else if (point === "destination") {
      handleLocationUpdate("destination", "LocationFromName");
    }
    setsearchMode(false); //remove search input and display input 
  };

  /// Search Filter options and Display.........................................
  const [isInputFill, setInputFill] = useState(false);
  const [showSkeleton, setshowSkeleton] = useState(false);
  const filterData = (e) => {
    e.preventDefault();
    setLoading(true);
    setshowSkeleton(true);
    setTimeout(() => {
      setshowSkeleton(false);
    }, 500);
    if (e.target.value.length >= 1) {
      setInputFill(true);
      let saisie = e.target.value.toLowerCase();
      setoptionRecords(
        optionData.filter((data) => data.Hotel && data.Hotel.toLowerCase().includes(saisie))
      );
    }
    if(e.target.value.length === 0){
      setInputFill(false);
      //setLoading(false)
      setoptionData(locationPoints);
      setoptionRecords(locationPoints);
    }
    
  };
  
  const mouseFunc =(e,name)=>{
    
    if(name==="mouseEnter"){
      setFriendlyZone(true)
    }
    if(name==="mouseLeave"){
      setFriendlyZone(false)
    }
  }
  
  // callingh api
  const removeSelection =(e)=>{
    e.preventDefault();
    let payload = {rowid,points}
    
    dispatch(removeLocation(payload))
    inpref. current. value = ''
    setPopupOpen((prevstate)=>({
      ...prevstate,
      value:false,
    }))
  
    axios.request({
      headers: {
        Authorization: `Bearer ${token}`
      },
      method: "GET",
      url: process.env.REACT_APP_API_URL+ LOCPOINT_ENDPOINT
    })
    .then(res => {
      let removed_airport = res.data.result.filter((data)=>{
        return data.HotelID !== 752
      })
      const timer = setTimeout(() => {
        //setLoading(false)
        setoptionData(removed_airport);
        setoptionRecords(removed_airport);
         }, 500); 
    })
    .catch((err)=>{
      if(err.response && err.response.status === 401){
        navigate("/")
      }
      console.log("get hotel err",err);
    });
   
  }

  const showData = (optionRecords) =>{
    return(
          (optionRecords.map((data, ind) => {
            return (
              <div  key={ind} className="OptionBox"  id={points + "option_" + ind} >
              
                  <div className="optionBoxDta" onClick={(e) => {optionValue(e,data.Hotel,data.Town,points,data.Town,data.HotelID)}}>
                      <div>
                        <img src={data.HotelID === 752 ?gray_airplane:img1}  alt="locationimg"/>
                      </div>
                      <div className="placeName">
                        <span>{capitalizeFirstLetter(data.Hotel)}</span>
                        <div className="placeDetails">{properCase(data.Town)}</div>
                      </div>
                  </div>
              
              </div>
            )
        }))
    )
  }


    return (
        <div className="select-container "  >
           {/* {!apiload ? */}
            <div>
              {/* //presentation choix */}
              <div
                className={"selectbox2 selectbox2cnt "+(passArray1 ===AIRPORT_LOCATION_NAME ? "locationBack_plane ":"locationImg") +(errmsg && passArray1 ===""? " errpoints": "" )}
                onClick={(e) => handleDropdown(e, "element")}
                style={{ display: searchMode ? "none" : "block" }}
                ref={inpref}
                tabIndex={passArray1 !== "" ? "1" : null}
              >
                <div id="select_deladd" className={"selectbox2cnt_sub "+(passArray1 !=="" ? "selectedLocation":"")}>
                  <div className={passArray1 === "" ? "no_selLabel":"selLabel"}>
                {passArray1 !== "" ? capitalizeFirstLetter(passArray1) : label}
                  </div>
                  <p className="selLabel_details">
                {passArray2 !== "" ? capitalizeFirstLetter(passArray2) : inpTextDetail}
                  </p>
                </div>
              </div>

            {/* recherche */}
              <input
                className="selectbox2 selbox1input" 
                placeholder={inpText}
                style={{
                  display: !searchMode ? "none" : "block",
                  backgroundColor: !popupOpen.elementHide ? "#edeae0" : "#fff",
                }}
                onClick={(e)=>handleDropdown(e,"element")}
                onChange={(e) => filterData(e)}
                id={"inputBox_" + points + passId}
                autoComplete="off"
                ref={inpref}
              ></input>



            </div>
         

         {/* ////POPUP */}
          {!popupOpen.elementHide ? (
            <div className={"selectbox-dropdown fade-in-300"+(passArray1 !== "" ? " autoHeight":"")   }  style={{transition:"0.3"}}   
              onMouseLeave={(e)=>{
                setPopupOpen((prevstate) => ({
                  ...prevstate,
                  elementHide: true,
                }));
                setsearchMode(false);
              }
              }
           
            >

              {/* //choix */}
            {passArray1 && passArray1.length > 0 ? (
              <div className="selected_data_location">
                <SelectedLocation seldata={passArray1} passId={passId} removefunc={removeSelection} points={points} />
              </div>
            ) : null}


                {/* //static ito  */}
              <div>
                <div className="optionBoxDta default" onClick={(e) => {optionValue(e,AIRPORT_LOCATION_NAME,"Sir Seewoosagur Ramgoolam",points,"South-East",752)}}>
                  <div>
                      <img src={gray_airplane}  alt="Airplane img"/>
                  </div>
                  <div className="placeName">
                      <span>Airport</span>
                      <div className="placeDetails">Sir Seewoosagur Ramgoolam</div>
                  </div>   
                </div>

              <hr></hr>
             
             {/* //manage  label on the top of list */}
              {
                isInputFill? 
                  <div className='popsearch' >Results</div> : <div className='popsearch'>Popular research</div> 
              }
              </div>
            {/* list hotel */}
            <div className={"scroll autoHeight"}> 
              {
                
                showSkeleton ? <B2BSkeleton imgUrl={ROOMS_SKELETON} /> :
                optionRecords && optionRecords.length > 0 ? showData(optionRecords) 
              :
                <div className="no_hoteldropdown"><p>Oops... Seems to have no results for your research</p></div>
              }
            </div>





            </div>
          ) : null}
        </div>
      );
}

export default LocPointsTransfer;