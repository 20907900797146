import React, { Fragment, useRef } from 'react'
import useOutsideClick from '../../hooks/useOutsideClick';
import LazyImage from '../MiniComponents/LazyImage';
import { getNumberOfPaxAccomodation } from '../../utility/globalFunc';
import DefaultRoomImg from "../../Assets/images/default-img/room.jpg";

function RoomOfferPopup({ roomName, mealPlan, pax, roomImg, closePopup, amenities }) {
  const popupRef = useRef(null);


  useOutsideClick(popupRef, () => {
    closePopup();
  });

  const getOfferType=(type)=>{
    switch (type) {
      case "PERCENTAGE":
        return "%"
    
      default:
        return "%";
    }
  }

  const getPaxType=()=>{
    return pax[0].clientType[0]
  }

  return (
    <div className='room-offer-popup' ref={popupRef}>
      <div className='room-details-container'>
        <LazyImage src={roomImg} className={"room-image"} alt={"room-iamge"}  fallBackImg={DefaultRoomImg} hasFallback={true}/>
        <div className='room-info-container'>
          <h4 className='room-name'>{roomName}</h4>
          <div className='clients-info-container'>
            <div className='clients-info pax-info'>{getNumberOfPaxAccomodation(pax)}</div>
            <div className='clients-info'>Client type<span>|</span><span className='light-weight'>{getPaxType()}</span></div>
            <div className='clients-info mealplan-info'>{mealPlan.PlanName}</div>
          </div>
          <div className='amenities-container'>
            <h4 className='amenities-section-title'>Global amenities</h4>
            <div className='amenities-items-container'>
              {
                amenities.map((amenity, ind) => (
                  <div className='amenities_list_other' style={{ background: `url(${amenity.AmenityIcon})no-repeat 11px,center` }} key={'amenity' + ind}>{amenity.AmenityName}</div>
                ))
              }
            </div>
          </div>
        </div>
      </div>

      <div className='offers-container'>
        {
          mealPlan.RoomTariff.map((tariff, indT) => (
            tariff.Offers.map((offer, ind) => (
              <Fragment key={"offer-" + ind + "-" + indT}>
                <div className='offer-details'>
                  {/* <h6 className='offer-title'>Offers applicable : 15% EARLY BIRD OFFER - 60 TO 89 DAYS PRIOR ARRIVAL (24/12/2024 to 23/12/2025) - 15 %</h6> */}
                  <h6 className='offer-title'>Offers applicable : <span className='offer-title-light'>{offer.OfferValue+getOfferType(offer.OfferType)}</span></h6>
                  <p className='offer-details'>
                    {offer.OfferName}
                  </p>
                </div>
                <i className='dashed-line'></i>
              </Fragment>
            ))))
        }
      </div>
    </div>
  )
}

export default RoomOfferPopup