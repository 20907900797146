import React,{useState,useEffect, useMemo } from "react";
import { useDispatch,useSelector } from "react-redux";
import { listTransfer, loadStatus, trigerId } from "../../store/transferReducer.js";
import CarCapacity from '../MiniComponents/CarCapacity.js';
import {REGION_DETAIL, TRANSFER_INPUT_OBJECT,MORE_OPTIONS_MODE} from "../../constants.js";
import { NoTransferResult } from "../MiniComponents/Atom.js";
import { Skeleton } from "@mui/material";
import transferStatic from "../../Assets/images/accomodation/staticImages/transferStatic.png"
import { scrollToSectionv1, selectedCarDetailsTemp,selectedSameVehicleTemp,updateTransferQuotation } from "../../utility/globalFunc.js";
import TransferCarList from "./TransferCarList.js";


function DisplayCar({passId,userData=[TRANSFER_INPUT_OBJECT],setId,noClick,position}) {
  

  const dispatch = useDispatch();
  const [capPopup,setCapPopup] =useState({pop:false,data:null,isAdapt:false})
  const { transferList,visitedTransferList:visitMode,regionDetails=[],loading,isResultSameVehicle} =useSelector((state) => state.transfer_Global)
  const { actionMode } = useSelector((state) => state.globalreducer)
  const {AdultCount,TeenCount,ChildCount,InfantCount}=userData[passId]||[TRANSFER_INPUT_OBJECT];
  const totalPax=useMemo(()=>getTotalPax(),[userData,passId]);
  

  function getTotalPax(){
    return AdultCount+TeenCount+ChildCount+InfantCount;
  }

  async function animationAddQuote() {
    const btnElement = document.getElementById('selectedBtn');

    if (!btnElement) {
      console.warn('Button element with ID "selectedBtn" not found.');
      return;
    }

    // Add loader class
    btnElement.classList.add('seldiv');

    // Disable scrolling
    const disableScroll = () => { document.body.style.overflow = 'hidden'; };
    const enableScroll = () => { document.body.style.overflow = 'auto'; };

    disableScroll();

    const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    // Trigger load status and wait for render to complete
    dispatch(loadStatus(true));
    await new Promise(requestAnimationFrame); // Ensure render has completed

    await wait(500);  // Wait if loader needs visible time

    dispatch(loadStatus(false));
    await new Promise(requestAnimationFrame); // Ensure render has completed

    enableScroll();
    await wait(100);  // Short delay before scrolling
    if (transferList.length > 1) {
      scrollToSectionv1("pickCarsText", 100);
    }

  }


  /// scroll after submit of popup
  useEffect(() => {
    if (capPopup.isAdapt){
      transferList.length > 1 ? dispatch(trigerId(passId)): null
      animationAddQuote();
      setCapPopup((prevstate)=>({
        ...prevstate,
        isAdapt:false
    }))
    }
  }, [capPopup.isAdapt])

  /// Select car function
  const selectedCar = async (e, selectDetails, ind, selid) => {
    //pour voir le changement du btn add quote avant de loder le skeleton
    e.preventDefault();
    let {MaxPax,status}=selectDetails
    let params = {userData,regionDetails,selectDetails,ind,selid,MaxPax,totalPax}
    console.log({...params});
    

    if(status === null && passId !== regionDetails.length - 1 && MaxPax > totalPax){
      dispatch(trigerId(passId+1));
        animationAddQuote();
    }
    // //open the popup for confirming the number of cars
    if(MaxPax < totalPax && status === null){
      setCapPopup((prevstate)=>({
        ...prevstate,
        pop:true,
        data:params,
        capacity:MaxPax
      }))
    }
    else{
      if(status === null ) animationAddQuote();
      let numberOfpax = { AdultCount, ChildCount, TeenCount, InfantCount };
      const payload = selectedCarDetailsTemp(params, numberOfpax);      
      //check its already saved quotation if yes
      actionMode === MORE_OPTIONS_MODE.ADD_ITEM && updateTransferQuotation(payload.transfer_data,actionMode)

      
      dispatch(listTransfer(payload))
    }

  }

  const checkMaxPaxTransfers=(transfers,maxPax)=>{
    const maxPaxObj={
      isAllMaxPaxFit:true,
      transfers:[]
    }

    if (transfers.some(transfer=>transfer.Car_selected)) {
      return maxPaxObj;
    }
    
    for (const transfer of transfers) {
      const {AdultCount,TeenCount,ChildCount,InfantCount}=transfer;
      const totalPax=AdultCount+TeenCount+ChildCount+InfantCount;
      
      if (maxPax<totalPax) {
        maxPaxObj.isAllMaxPaxFit=false;
        maxPaxObj.transfers.push(transfer);
      }
    }
    return maxPaxObj;
  }

  const selectedSameVehicle=async(e,selectDetails, ind, selid)=>{
    e.preventDefault();
    let {MaxPax,status}=selectDetails
    let params = {userData,regionDetails,selectDetails,ind,selid,MaxPax,totalPax}
    const maxPaxFit=checkMaxPaxTransfers(userData,MaxPax);
    console.log({userData1:userData});
    
    
    if(!maxPaxFit.isAllMaxPaxFit){
      const adaptPax=confirm("Some transfers do not correspond to the capacity of the vehicle. Would you like to adapt the number of vehicles for these?");
      if (!adaptPax) {
        return;
      }
    }
    if(status === null ) animationAddQuote();
    let numberOfpax = { AdultCount, ChildCount, TeenCount, InfantCount };
    const payload = selectedSameVehicleTemp(params, numberOfpax);
    dispatch(listTransfer(payload))

  }

  const selectVehicle=async (e,selectDetails, ind, selid)=>{
    if (isResultSameVehicle) {
      selectedSameVehicle(e,selectDetails, ind, selid)
      
    }else{
      selectedCar(e,selectDetails, ind, selid);
    }
  }


  const alt_regionDetails = visitMode ? REGION_DETAIL : regionDetails;
  


  return (
    <>
      <div className="displayCarParent">
        {loading ? (
          <Skeleton
            style={{
              borderRadius: "17px",
              transform: "scale(1, 1)",
              background: "linear-gradient(90deg, #f0f0f0, #e0e0e0, #f0f0f0)",
              backgroundSize: "200% 100%",
              animation: "shimmer 1.5s infinite",
            }}
          />
        ) 
        : alt_regionDetails && alt_regionDetails[passId] ? (
          alt_regionDetails[passId].map((data, ind) => (
            <div
              key={`${data.TransferTariffId}_${ind}`}
              className="mapping_car"
            >
              <TransferCarList
                visitMode={visitMode}
                passId={passId}
                data={data}
                ind={ind}
                noClick={noClick}
                selectedCar={selectVehicle}
              />
            </div>
          ))
        )
        : (
          <NoTransferResult />
        )}


    </div> 
      
      {capPopup.pop?
        <div className='deletepopup_container' style={{paddingToptop:"80px"}}>
         <CarCapacity setCapPopup={setCapPopup} data={capPopup.data} /> 
        </div>
      :null
      }

    </>
  );
}

export default DisplayCar;