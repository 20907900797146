import React, { Fragment, useEffect, useLayoutEffect, useRef, useState } from 'react';
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineContent from "@mui/lab/TimelineContent";
import { getTransferSuplement, isServiceAccomodation, isServiceTransfer, parseDateString, scrollToSection } from "../../utility/globalFunc.js"
import useMoreOptionQuotation from '../../hooks/useMoreOptionQuotation.js';
import { useSelector } from 'react-redux';
import AccomodationPattern from './TimelineComponents/AccomodationPattern.js';
import TransferPattern from './TimelineComponents/TransferPattern.js';
import useDocket from '../../hooks/useDocket.js';

function TimelineTransfer({ containerRef, transferData, showRemove = false }) {
  const { numberOfRoom: numberOfRoomStatus, pendingRooms } = useSelector((state) => state.accomodationreducer);
  const { sortedList,isQuoteUpdating } = useSelector((state) => state.globalreducer)
  const { tempId: currentIdTransfert,paxdata,totalpax,transferPax,isResultSameVehicle } = useSelector((state) => state.transfer_Global)
  
  const [services, setServices] = useState([])
  const [activeIndex, setActiveIndex] = useState(0);
  
  const { removeItem } = useMoreOptionQuotation();
  const {getTimeline,buildServiceAccom}=useDocket();
  
  let transferNum = 1;

// console.log("transferData:",transferData);



  const incrementTransferNum=()=>{
    transferNum++;
    return null;
  }
  
  transferData = transferData.sort((a, b) => {
    const aLatestDate = parseDateString(a.date[a.date.length - 1]);
    const bLatestDate = parseDateString(b.date[b.date.length - 1]);
    return bLatestDate - aLatestDate;
  })
  // let currency =  0
  // let currency = transferList.length ? transferData[0].currency : 0
  // Create a ref for the container
 

  useEffect(() => {
    const targetId = `transfer${currentIdTransfert + 1}`;
    if (containerRef.current) {
      scrollToSection(targetId, containerRef.current, 10); // Passer le container ref
    } else {
      // console.log('containerRef est toujours null');
    }
  }, [services]);
  

  useEffect(() => {

    const options = {
      root: containerRef.current,
      threshold: 0.8 // 
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const index = parseInt(entry.target.getAttribute('data-index'), 10);
          setActiveIndex(index);
        }
      });
    }, options);

    const items = containerRef.current.querySelectorAll('.quotation-map-parent');
    items.forEach((item) => observer.observe(item));

    return () => {
      items.forEach((item) => observer.unobserve(item)); // Nettoyage 
    };
  }, [transferData]);


  // const updatedTransferData = transferData.map((item, index) => ({
  //   ...item,
  //   isFocused: activeIndex === index, // Remplacez `focusedIndex` par `activeIndex`
  // }));

  useEffect(() => {
    const accoms= buildServiceAccom(pendingRooms,sortedList.services.reservationAccom);
    const transfers=sortedList.services.reservationTransfer;
    setServices(getTimeline({accomodations:accoms,transfers}));
  }, [sortedList, pendingRooms])


  return (
    <div ref={containerRef} className={"quotation-loop-parent " + (transferData.length >= 2 ? "quotation-loop-scroll" : "")}>

      <div
        className={`quotation-map-parent`}
        tabIndex={0}
      >
        <Timeline>
          <TimelineItem>
            <TimelineSeparator>
            </TimelineSeparator>
            <TimelineContent>

              {/* {put here UI transfert ussing serviceTrans} */}

              {/* {console.log({services})} */}
              <>
                {services.map((service, index) => (
                  <Fragment key={"accomservice" + index}>
                    {isServiceAccomodation(service)&&<AccomodationPattern
                      isNew={service.isNew}
                      showRemove={showRemove}
                      removeItem={()=>removeItem(service, 'accom')}
                      dates={service.Dates}
                      rooms={service.Rooms}
                      totalPrice={service.Total}
                      currency={service.Currency}
                      hotelName={service.HotelName}
                    />}
                    {isServiceTransfer(service)&&
                    <>
                      <TransferPattern
                        isNew={service.isNew}
                        showRemove={showRemove}
                        transferNo={transferNum}
                        date={service.TransferDate}
                        locationFrom={service.LocationFromName}
                        locationTo={service.LocationToName}
                        TransferCategoryName={service.TransferCategoryName}
                        vehicleType={service.Vehicle_type}
                        capacity={service.capacity}
                        carSelected={service.Car_selected}
                        adultCount={service.AdultCount}
                        teenCount={service.TeenCount}
                        childCount={service.ChildCount}
                        infantCount={service.InfantCount}
                        totalpax={totalpax}
                        supplements={getTransferSuplement(service)}
                        price={service.TotalPrice}
                        currency={service.Currency}
                        removeItem={()=>removeItem(service, 'transfer')}
                        carCount={service.CarCount}
                        isSameVehicle={isResultSameVehicle}
                      />
                      {incrementTransferNum()}
                    </>
                    }
                    {/* <div className='sel-excursion-map' style={{ marginTop: "8px", marginBottom: "13px" }}>
                      <div className='containerPrice'>
                        <div className='module-name accom'>Accommodation {accom.hasOwnProperty("isNew") && <i className='iconNew'>New</i>}</div>
                        {showRemove && <button id='remove' className='remove-button' onClick={() => removeItem(accom,'accom')}><span>Remove</span></button>}
                        {accom.Total?<div style={{ display: showRemove ? 'none' : 'block' }} id='price' className='price'><p>{accom.Currency} {separateurMillier(accom.Total,',')}</p></div>:null}
                      </div>
                      <div style={{ paddingLeft: "16px" }}>
                        <div className='quotation_tour_name excursion-map '>{accom.HotelName}</div>
                        <div className='quotation_tour_date excursion-map dates'><span className='quotation_tour_date_first-date'>{accom.Dates[0]}</span><span className='quotation_tour_date_arrow'>&rarr;</span><span className='quotation_tour_date_first-date'>{accom.Dates[1]}</span></div>
                        <div className='excursion-map nights-docket'>{calculateNights(accom.Dates[0], accom.Dates[1])} nights</div>
                        {
                          accom.Rooms.map((room, index) => {
                            return (
                              <Fragment key={"roomdock" + index}>
                                {
                                  room.isEmpty ?
                                    <EmptyRoomTimeline roomNo={index + 1} />
                                    :
                                    <FilledRoomTimeline isMonoRoom={accom.Rooms.length > 1} room={room} roomNo={index + 1} />
                                }
                              </Fragment>
                            )
                          })
                        }
                      </div>
                    </div> */}
                  </Fragment>
                ))}
              </>

            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </div>
      {/* : null} */}
    </div>
  );
}

export default TimelineTransfer;