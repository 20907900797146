import React from 'react';
import BookingPerson from '../Transfer/BookingPerson';
import Multicalander from './Multicalander';
import SameInputs from './SameInputs';
import {paxData,transformArray,additionalFilter,additionalhotel} from "../../constants";
import { useNavigate } from 'react-router-dom';
import axios from "axios"
import { useSelector,useDispatch } from 'react-redux';
import {listExcursion} from "../../store/excursionReducer.js"
import {formatDateString} from "../../utility/globalFunc.js"


function ExcursionInputs({alignbtn,hideBtmsearch}) {
    const inputsDate = useSelector((state) => state.excursionreducer.calendarDate)
   // const {Adult,Teen,Child} = useSelector((state) => state.globalreducer.paxData)
   const paxInfo = useSelector((state) => state.excursionreducer.paxData)
   const {Adult,Teen,Child,Infant} = useSelector((state) => state.excursionreducer.paxData[0])

    const navigate = useNavigate();
    const dispatch = useDispatch()
    let token = localStorage.getItem("access-token")
    let agency_id = Number(localStorage.getItem("agency_id"))
    let left ={marginLeft:"84.5px"}
    const paxLeft = {marginLeft:"15px"}

    const searchData =(e)=>{
        e.preventDefault();
    //    let obj = {
    //         "types": ["sea", "land", "tailor-made"],
    //         "category": ["Cultural", "Wild life", "Historical", "Nature", "Educative"]
    //     };
    //     console.log("send obj:",obj);
        
        // console.log("filterData:",filterData);
    if(inputsDate.length >1){
        let sendObject = 
        {
            "date_from": formatDateString(inputsDate[0],"dd/mm/yyyy","yyyy-mm-dd"),
            "date_to": formatDateString(inputsDate[1],"dd/mm/yyyy","yyyy-mm-dd")
        }
        console.log(sendObject);
        
        axios.request({
            headers: {
            Authorization: `Bearer ${token}`
            },
            method: "POST",
            url: process.env.REACT_APP_API_URL+`tours/b2b/list`,
            data: sendObject,
        })
        .then(res => {
            console.log(res);
            let upadtedFilter = {...res.data.options,...additionalFilter};
            //res.data.result.push(additionalhotel)

            let payload = {lists:res.data.result, options:transformArray(upadtedFilter)}
            console.log("payload:",payload);
            dispatch(listExcursion(payload))
            navigate("/excursion-select");
        })
        .catch((err) => {
            if(err.response.status === 401){
                navigate("/")
            }else{
                console.log(err);
            }
        });

    }
else{
    alert("please fill the fields")
}
     
    }

    return (
        <div className={'excursion_inputs_parent '+(alignbtn ? "":"rempad")} >
            <div className={'excursion_inputs_boxes '+(!alignbtn ? "newbtn_added":"")}>
                
                <Multicalander  label="Search excursion From" label2={inputsDate.length === 0  ?"dd/mm/yyyy":inputsDate[0]}  rangeDate={inputsDate} month={2} page={3}/>
                <div className='excursion_inputs_line'></div>
                <Multicalander  label="Search excursion To"   label2={inputsDate.length === 0 || inputsDate.length === 1  ?"dd/mm/yyyy":inputsDate[1]} rangeDate={inputsDate} month={2} page={3}/> 
                <div style={!alignbtn ? paxLeft :left}><BookingPerson scroll={false} cname={3} paxInfo={paxInfo} Infant={Infant} Adult={Adult} Teen={Teen} Child={Child}/></div>   
                {!alignbtn ?<button className='ex_search' onClick={(e)=>{searchData(e)}}>Search</button>:null}
            </div>
            {alignbtn || hideBtmsearch ?
            <div className='btnblock'>
               {!hideBtmsearch ? <button className='ex_search' onClick={(e)=>{searchData(e)}}>Search</button>:<></>}
                <div className='same_inputs'><SameInputs label="See excursion available by period" type="checkbox"/></div>
            </div>:null}
        </div>
    );
}

export default ExcursionInputs;