import React from "react";
import MyRoutes from "./MyRoutes";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Assets/styles/scss/newstyle.scss';

function App() {

  return (
    <div className="App">
      <div className="mainParent">
        <div className="loading_components"><MyRoutes /></div>
      </div>
    </div>
  );
}

export default App;