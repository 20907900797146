import { axiosRequestParams } from "../services/apiClient";
import { BOOKING_ENDPOINT } from "../services/apiEndpoints";

const useMoreOptionBooking=()=>{
    const cancelBooking=({refcode,onSuccess,onError})=>{
        const url=BOOKING_ENDPOINT+"?ToRef="+refcode
        axiosRequestParams("DELETE",url)
        .then((res)=>{
            onSuccess&&onSuccess(res);
        }).catch(error=>{
            onError&&onError(error);
        })
    }
    return {cancelBooking};
}

export default useMoreOptionBooking;