import { createSlice } from '@reduxjs/toolkit'
import {BUDGET_RANGE, STARRATING} from "../constants.js"
import {backenedDates, getInitialPendingRooms, getRoomsGroupKey} from "../utility/globalFunc.js"

const INITIAL_STATE = {
  accomList:[],
  accomfilter:[],
  calendarDate:[],
  backendDate:[],
  roomPax:[{"adult":1,"teen":0,"child":0,"infant":0,"room_id":1,"paxAge":[],clientType:["Standard","STD"]}],
  roomPaxBookingDefault:[{"adult":0,"teen":0,"child":0,"infant":0,"room_id":1,"paxAge":[],clientType:["Standard","STD"]}],
  resetroomPaxBooking:[{"adult":0,"teen":0,"child":0,"infant":0,"room_id":1,"paxAge":[],clientType:["Standard","STD"]}],
  selectedHotel:{loc_name:"",loc_det:"",loc_id:"all"},
  selRooms:[],
  createRooms:[],
  clientName:[],
  stayNights:null,
  starRating:STARRATING,
  pendingRooms:{requiredNbRooms:0,nbNewRooms:0,roomsFilled:[],key:'',hotelId:"",date:null,reloadRoomNumber:false},
  //accomLoading:{loadingScreen1:false,loadingScreen2:false},
  accomLoading:false,
  roomStatus:{loading:false,error:null},
  criteria:[],
  pageLoading:{status:false,firstFlow:true},
  paginationPage:1,
  noHotelData:false,
  isOffer:false,
  offset:0,
  isNewHotel:false,
  showButtonRemove:false,
  numberOfRoom:[],
  suggestionLoading:false,
  hotelSuggestions:[],
  budgetRange:[BUDGET_RANGE.MIN_PRICE,BUDGET_RANGE.MAX_PRICE],
  displayUnavailableHotels:false,
}




export const accomodationData = createSlice({
  name: 'accomodationreducer',
  initialState:INITIAL_STATE,
  reducers: {
        accomDates(state, action) {
            state.calendarDate = action.payload;
            state.backendDate = state.calendarDate.length > 1 ? backenedDates(state.calendarDate):[]
        },
        clearAccomDates(state, action) {
          state.calendarDate = []      
        }, 
        listAccomodation(state, action) {
          //state.accomList = [...state.accomList, ...action.payload];
          // Concatenate the current accomList with the new data from action.payload
          let combinedList = [...(state.accomList || []), ...action.payload];


          // Remove duplicates based on the 'id' property
          const uniqueList = combinedList.reduce((acc, current) => {
              // Check if the 'id' of the current item exists in the accumulator (acc)
              if (!acc.find(item => item.HotelId === current.HotelId)) {
                  acc.push(current);  // If not found, add the current item to the accumulator
              }
              return acc;
          }, []);
          
            // Update the state with the unique list
            state.accomList = uniqueList;


        },
        filterAccomodation(state, action) {
          state.accomfilter = action.payload
        },
        updateAccomlist(state, action) {
          let {lists} = action.payload
            state.accomList = lists
        },
        addNights(state,action){
          state.stayNights = action.payload
        },
        setSelRooms(state,action){
          state.selRooms=action.payload;
        },
        selectedRooms(state,action){
          let{room,roomNo}=action.payload
          
          // const existingIndex = state.selRooms.findIndex(obj => obj.idAccom === idAccom);
          /* if (existingIndex !== -1) {
            // If ID exists, remove the object
            const newData = [...state.selRooms];
            newData.splice(existingIndex, 1);
            state.selRooms = newData
          } else { */
            // If ID doesn't exist, add the new object
            let insertIndex=state.selRooms.length-roomNo;
            let newSelRooms=[...state.selRooms];
            newSelRooms.splice(insertIndex,0,room)
            
            
            state.selRooms = newSelRooms
            // state.selRooms.sort((a, b) => a.idAccom - b.idAccom);
          // }
          

        },
        accomPax(state,action){
          console.log("action.payload;",action.payload);
          
          state.roomPax  = action.payload;
        },
        accomPaxBookingDefault(state,action){
          state.roomPaxBookingDefault  = action.payload;
        },
        resetaccomPaxBookingDefault(state,action){
          state.resetroomPaxBooking  = action.payload;
        },
        clearAccom(state, action){
          return INITIAL_STATE;
        },
        createRoom(state,action){
          
          state.createRooms = action.payload
        
         //pour gerer le btn switch room selected
          // Création de l'objet numberAndStatus à partir de la longueur de action.payload
          let numberAndStatus = action.payload.map((_, index) => ({
            number: index + 1,
            isActive: 'false'
          }));
          // On assigne numberAndStatus à state.numberOfRoom
          state.numberOfRoom = numberAndStatus;
        },
        isRoomLoading(state,action){
          let {isLoading=false,errorMsg=null} = action.payload
          state.roomStatus.loading = isLoading
          state.roomStatus.error = errorMsg
        },
           
        updateMealPlan(state,action){
          console.log("action",action.payload);
          let {roomNum,subroomInd,mealId} = action.payload
          
          state.createRooms.forEach((mainroom,roomNo) =>{
              if(roomNo === roomNum){
                console.log(mainroom[0])
              }
          })

        },
        clearRoom(state){
          state.selRooms =[];
          state.numberOfRoom =[];
        },
        accomClient(state,action){
          let {value,name,roomId} = action.payload
          state.clientName[roomId] = value
        },
        updateRating(state,action){
            state.starRating = action.payload
        },
        setAccomLoading(state,action){
          state.accomLoading = action.payload
        },
        addCriteria(state,action){
          state.criteria = action.payload
        },
        // removeCriteria(state){
        //   state.criteria = []
        // },
        // updateCriteria(state,action){
        //   state.criteria = action.payload
        // },
        selHotel(state,action){
          state.selectedHotel = action.payload
        },
        setPageLoading(state,action){
          const { status, isFirstFlow } = action.payload;
          const firstFlow = isFirstFlow === null ? true : isFirstFlow;
          state.pageLoading = {
            ...state.pageLoading,
            status,
            firstFlow: firstFlow,
          };
        },
        removeQuotationItem(state,action){
          let idAccom=action.payload
          for (let i = 0; i < state.selRooms.length; i++) {
            if (state.selRooms[i].idAccom===idAccom) {
              state.selRooms.splice(i,1);
              break;
            }
          }
          for (let i = 0; i < state.createRooms.length; i++) {
            let createRoom=state.createRooms[i];
            if (createRoom.idAccom===idAccom) {
              createRoom.RoomStay.forEach(room=>{
                room.selectedStatus=null;
              });
              state.createRooms[i]=createRoom;
              delete state.createRooms[i].idAccom;
              if (state.pendingRooms.roomsFilled[i]) {
                state.pendingRooms.roomsFilled[i]=false
              }
              break;
            }
          }
          state.showButtonRemove = true;
        },
        resetButtonRemove(state, action){
          state.showButtonRemove = false;
        },
        resetCreateRoom(state,action){
          state.createRooms?.forEach(rooms=>{
            rooms?.RoomStay?.forEach(hotelRoom=>{
              hotelRoom.selectedStatus=null;
            })
          })
        },
        chooseMealPlan(state,action){
          let {roomNo, roomId,mealPlanId}=action.payload;
          let index=state.createRooms[roomNo].RoomStay.findIndex((room)=>room.RoomID===roomId);
          let mealPlans=state.createRooms[roomNo].RoomStay[index].RoomRatePlan;
          for (let i = 0; i < mealPlans.length; i++) {
            mealPlans[i].IsBase=mealPlans[i].PlanID===mealPlanId;
          }
          state.createRooms[roomNo].RoomStay[index].RoomRatePlan=mealPlans;
        },
        selectCreateRooms(state,action){
          let {roomNo,roomId,idAccom}=action.payload;
          for (let i = 0; i < state.createRooms[roomNo].RoomStay.length; i++) {
            let element = state.createRooms[roomNo].RoomStay[i];
            if (element.RoomID === roomId && !element.selectedStatus) {
              element= { ...element,idAccom, selectedStatus: "selected" };
            } else if (!!element.selectedStatus) {
              element= { ...element,idAccom, selectedStatus: null };
            } else {
              element= { ...element,idAccom, selectedStatus: "not-selected" };
            }
            state.createRooms[roomNo].RoomStay[i]=element;
            state.createRooms[roomNo].idAccom=idAccom;
            // let 
            state.pendingRooms.roomsFilled[roomNo]=true;
          }
        },
        incPaginationNumber(state,action){  
          state.paginationPage = action.payload
        },
        ishotelListInfo(state,action){
          state.noHotelData = action.payload
        },
        setIsOffer(state,action){
          state.isOffer=action.payload;
        },
        resetIsOffer(state,action){
          state.isOffer=false;
        },
        resetIsnewHotel(state,action){
          state.isNewHotel=action.payload
        },
        resetPendingAccomGroupRooms(state,action){
          let { itemHotelId, itemDate } = action.payload;
          if(state.pendingRooms.hotelId===itemHotelId&&JSON.stringify(state.pendingRooms.date)===JSON.stringify(itemDate)){
            state.pendingRooms=getInitialPendingRooms(state.createRooms.length,state.pendingRooms);
          }
        },
        resetPendingRooms(state,action){
          state.pendingRooms=getInitialPendingRooms(state.createRooms.length,state.pendingRooms,INITIAL_STATE.pendingRooms);
        },
        removePendingRooms(state,action){
          state.pendingRooms=INITIAL_STATE.pendingRooms;
        },
        setPendingRooms(state,action){
          let {nbPendingRooms,date,hotelId,hotelName,key,nbNewRooms,startRoomIndex}=action.payload;
          // console.log();
          
          state.pendingRooms={
            requiredNbRooms:nbPendingRooms,
            nbNewRooms:nbNewRooms,
            date:date,
            hotelName:hotelName,
            key:key||getRoomsGroupKey(date,hotelId),
            hotelId:hotelId,
            roomsFilled:Array.from({length:nbNewRooms},(_,i)=>(false)),
            startRoomIndex
          };
        },
      removeAccomgroupe(state, action) {
        let { itemHotelId, itemDate } = action.payload;
        // Filtrer pour conserver uniquement les chambres qui n'ont pas le même hotelId et les mêmes dates
        state.selRooms = state.selRooms.filter(room =>
          room.hotelId !== itemHotelId || !room.date.every(date => itemDate.includes(date))
        );
        state.createRooms?.forEach(rooms => {
          rooms?.RoomStay?.forEach(hotelRoom => {
            hotelRoom.selectedStatus = null;
          })
        })
      },
restoreStatusRoom(state, action) {
    state.createRooms = state.createRooms.map(room => {
        const updatedRoomStay = room.RoomStay.map(stay => {
            return {
                ...stay,
                selectedStatus: null // Réinitialiser à "not-selected"
            };
        });
        return {
            ...room,
            RoomStay: updatedRoomStay
        };
    });
},
      setSuggestionLoading(state,action){
        let {loading}=action.payload;
        state.suggestionLoading=loading
      },
      setHotelSuggestions(state,action){
        let {suggestions}=action.payload;
        state.hotelSuggestions=suggestions
      },
      resetHotelSuggestions(state,action) {
        state.hotelSuggestions=INITIAL_STATE.suggestions
      },
      setBudgetRange(state,action){
        let {budgetRange}=action.payload;
        state.budgetRange=budgetRange;
      },
      setNumberOfRoom(state,action){
        
      },
      clearOnGoBack(state,action){
        return {
          ...INITIAL_STATE,  
          accomfilter: state.accomfilter,  
          calendarDate: state.calendarDate,
          backendDate: state.backendDate,
          criteria: state.criteria,
          starRating: state.starRating,
          roomPax:state.roomPax,
          isOffer: state.isOffer,
          budgetRange:state.budgetRange,
        };
      },
      setDisplayUnavailableHotels(state,action){
        state.displayUnavailableHotels=action.payload;
      },

    setOffset(state,action){
      state.offset = action.payload
    }
       
  },
})

export const {isRoomLoading,setOffset,restoreStatusRoom,removeAccomgroupe,resetIsnewHotel,resetButtonRemove,clearAccom,clearRoom,incPaginationNumber,ishotelListInfo,accomClient,updateMealPlan,filterAccomodation,addAgeInfo,selHotel,addNights,addCriteria,setAccomLoading,updateRating,createRoom,selectedRooms,accomPax,accomDates,updateAccomlist,clearAccomDates,listAccomodation,setPageLoading,removeQuotationItem,resetCreateRoom,setSelRooms,chooseMealPlan,selectCreateRooms,setIsOffer,resetIsOffer,accomPaxBookingDefault,resetaccomPaxBookingDefault,setPendingRooms,resetPendingRooms,setSuggestionLoading,resetHotelSuggestions,setHotelSuggestions,setBudgetRange,resetPendingAccomGroupRooms,clearOnGoBack,setDisplayUnavailableHotels,removePendingRooms} = accomodationData.actions

export default accomodationData