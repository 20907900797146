import axios from 'axios';

export const axiosRequestParams =(reqMethod,endpoint,payload)=>{

    console.log("last",payload);
    
    let token = localStorage.getItem("access-token");

    //console.log("token",token);
    
    return axios.request({
        headers: {Authorization: `Bearer ${token}`},
        method: reqMethod,
        url: process.env.REACT_APP_API_URL+`${endpoint}`,
        params:payload
    });
}

export const axiosRequestBody =(reqMethod,endpoint,payload,config = {})=>{
    let token = localStorage.getItem("access-token");
    return axios.request({
        headers: {Authorization: `Bearer ${token}`},
        method: reqMethod,
        url: process.env.REACT_APP_API_URL+`${endpoint}`,
        data:payload,
        ...config
    });
}

export const axiosRequestBody1 =(reqMethod,endpoint,config = {})=>{
    let token = localStorage.getItem("access-token");

    return axios.request({
        headers: {Authorization: `Bearer ${token}`},
        method: reqMethod,
        url: process.env.REACT_APP_API_URL+`${endpoint}`,
        ...config
    });
}

export const axiospagination =(reqMethod,endpoint,payload,config = {},page)=>{
    let token = localStorage.getItem("access-token");
    return axios.request({
        headers: {Authorization: `Bearer ${token}`},
        method: reqMethod,
        url: process.env.REACT_APP_API_URL+`${endpoint}`,
        data:payload,
        ...config
    });
}

export const axiosLogin =(reqMethod,endpoint,payload)=>{
    const credentials = btoa(`${payload.username}:${payload.password}`);
    return axios.request({
        headers: { Authorization: `Basic ${credentials}`}, 
        method: reqMethod,
        url: process.env.REACT_APP_API_URL+`${endpoint}`,
    });
}

export const axiosParamsAgency =(reqMethod,endpoint,payload)=>{
    let token = localStorage.getItem("mtco-token");
    
    return axios.request({
        headers: {Authorization: `Bearer ${token}`},
        method: reqMethod,
        url: process.env.REACT_APP_API_URL+`${endpoint}`,
        ...(reqMethod === 'POST' && { params: payload })
    });
}

