import React, { useState } from 'react';
import { updateLists } from "../../store/excursionReducer.js"
import StarRatingMenu from '../Accomodation/minicomponents/StarRatingMenu.js';
import { updateAccomlist, addCriteria } from "../../store/accomodationReducer.js"
import { capitalizeFirstLetter, STARRATING, REGION, removeUnderscore } from "../../constants.js"
import useFilterItems from '../../hooks/useFilterItems.js';
import { useDispatch, useSelector } from 'react-redux';
import OfferFilter from '../Accomodation/minicomponents/OfferFilter.js';
import PriceSlider from '../Accomodation/minicomponents/PriceSlider.js';

function ExcursionFilter({  filterOptions,scrollRef,setLoading=undefined }) {
    const[ratingData, setratingData] = useState(STARRATING);
    const {renderCheckboxes,getOrderFilter,updateBudgetRange} = useFilterItems()
    const {starRating,budgetRange} = useSelector((state) => state.accomodationreducer)
    const {userData}=useSelector(state=>state.authpath)
    const [updataFilterOrder,setUpdataFilterOrder] = useState(filterOptions)
    let service = "excursion"
  const selectStarRating = (e, item) => {
    e.preventDefault();
    let { id, rating } = item
    //console.log(item);
    setratingData(prevStarRatings => {
      return prevStarRatings.map(star => {
        if (star.id === id) {
          return { ...star, checked: !star.checked };
        }
        return star;
      });
    });
  }
  // console.log(filterOptions);

  let orderedFilter=getOrderFilter(filterOptions,service);
    
  

  


    return (
        <div className='excursion_filter_parent'>
          <PriceSlider currency={userData.currency} defaultValues={budgetRange} onValueCommitted={updateBudgetRange} minDistance={2500} disableSwap={true}/>
          <div style={{float:"left"}} className='hotel_star_rating'>
            <div className='filter_type_name'>Hotel star rating</div>
            <StarRatingMenu data={starRating} selectStarRating={selectStarRating} needApi={true} scrollRef={scrollRef}/>
          </div>
          <div className='filter_boxes_excursion'>
            {orderedFilter.map(category => renderCheckboxes(category, category.tags, filterOptions,scrollRef))} 
          </div>
    </div>
  );
}

export default ExcursionFilter;
