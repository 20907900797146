import React,{useState,useEffect} from 'react';
import Dropdowns from '../Transfer/Dropdowns';
import {DROPDOWN_DATA,filterarray} from "../../constants"
import ExcursionFilter from './ExcursionFilter';
import FiltersData from './FiltersData';
import ExcursionInputs from './ExcursionInputs';
import Footer from '../Transfer/Footer';
import Showhotels from '../Accomodation/Showhotels';
import { useSelector } from 'react-redux';
import Quotation from '../Transfer/Quotation.js';
import NavbarBlock from '../Transfer/NavbarBlock.js';


function MainExcursion() {
    const {quotation,excursionList,filters,selExcursion,paxData} = useSelector((state) => state.excursionreducer)
    //const {Adult,Teen,Child,Infant} = useSelector((state) => state.excursionreducer.paxData[0])
    const [checkedItems, setCheckedItems]= useState([])
    const list_structure1 = {gridTemplateColumns: "207px auto"}
    const [selectedFilters, setSelectedFilters] = useState({});

   
    const isFilterSelect = Object.values(selectedFilters).some(value => value.length > 0);
   
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    }, []);
   
    
    return (
        <>
        <NavbarBlock/>
        <div className='mainexcursion_parent'>
            
            <div className='sub_mainexcursion_parent'>
                <div className='filter_parent'>
                    <Dropdowns dropData={DROPDOWN_DATA} imgcount={3} name="Excursion"/>
                    <div className='filter_list'>
                        <div className='filter_list_name'>Filters</div>
                        <div> 
                            <ExcursionFilter 
                                //data={Object.keys(filters).length === 0 ? "no records":filters}
                                filterOptions={filters}
                                list={excursionList}
                                module={2}
                                setSelectedFilters={setSelectedFilters}
                                selectedFilters={selectedFilters}
                            /> 
                        </div> 
                    </div>
                </div>
                {/* <div className='filter_hotels'>
                        <div className='back_items'>Back | <span>Excursion List</span></div>
                        <ExcursionInputs alignbtn={false} />
                        {isFilterSelect ? <FiltersData label="Filters applied :" data={selectedFilters} setSelectedFilters={setSelectedFilters}/>:<></>}   
                        <div className='excursion_places'>
                            <div className='places_hdtxt'>Our excursions</div>
                            <Showhotels btnBlock={true} hotel={excursionList} foldername="excursion" url="/excursion/details" redirect="/selected-excursion" paxInfo={paxData[0]}/>
                        </div> 
                </div> */}
                {quotation ?<div className='fixedQuotation'><Quotation cname="Excursion" details={selExcursion}/></div>:null}
            </div>
            
            <Footer/>
        </div>
        </>
    );
}

export default MainExcursion;