import React, { useRef, useState,useEffect } from 'react'
import { useSelector,useDispatch } from 'react-redux'
import useMoreOptionQuotation from '../../hooks/useMoreOptionQuotation'
import useOutsideClick from '../../hooks/useOutsideClick';
import SameInputs from "../Excursion/SameInputs";
import useAddItems from '../Quotations/hooks/useAddItems';
import TableLayoutNew from './TableLayoutNew';
import {exportToPDF} from '../../utility/globalFunc';
import { MORE_OPTIONS_MODE } from '../../constants';
import {setActionMode, setShowMoreTimeLine} from "../../store/globalReducer"
import CircularProgress from '@mui/material/CircularProgress';


export function CancelUpdatePopup({closePopup}) {
    const {refCode}=useSelector(state=>state.transfer_Global)
    const {cancelAddItemMode}=useMoreOptionQuotation();
    const [className,setClassName]=useState("");
    const popupRef=useRef();
    const handleCancel=(e)=>{
      e.preventDefault();
      animateClose(cancelAddItemMode);
    }

    const animateClose=(additionalAction)=>{
      setClassName("fade-out-300");
      setTimeout(() => {
        closePopup();
        setClassName("");
        additionalAction&&additionalAction()
      }, 200);
    }

    useOutsideClick(popupRef,()=>{
      animateClose();
    })

  return (
    <div className={`cancel-update-container ${className}`} ref={popupRef}>
        <h4 className='cancel-update-title'> You are about to delete?</h4>
        <p className='refCode-subtitle'><span className='refCode-label'>“{refCode}”</span>&nbsp;quotation</p>
        <button onClick={handleCancel}  className='cancel-update-button'>Cancel</button>
        <div onClick={()=>animateClose} className='continue-update-button'>Continue adding items</div>
    </div>
  )
}


export const QuoteDeletePopup =({name,closePopup})=>{
  const closePopref =useRef(null)
  const {deleteQuotation,loading}=useAddItems()
  const dispatch = useDispatch();


  useOutsideClick(closePopref,()=>{
    dispatch(setActionMode(null))
    closePopup();
  })

  const callDeletequote=()=>{
    let payload ={"to_ref":name,"service_type":"ALL"}
    console.log("loading:",loading);
    
    deleteQuotation(payload,MORE_OPTIONS_MODE.DELETE_QUOTATION)
  }



  return(
      <div className='cancel-update-container' ref={closePopref}>
          <h4 className='cancel-update-title'> You are about to delete?</h4>
          <p className='refCode-subtitle'><span className='refCode-label'>“{name}”</span>&nbsp;quotation</p>
          <div style={{display:"flex",gap:"20px"}}>
            <button className='continue-update-button' onClick={()=>closePopup()}>Cancel</button>
            <button className='cancel-update-button' onClick={()=>callDeletequote()}>
              {loading ? <CircularProgress color="secondary" style={{ width: "20px", height: "20px", color: "#fff", marginLeft: "8px" }}/> : "Delete"}
            </button>

          </div>
          
      </div>
  )
}


export const QuoteDeleteService =({data,closePopup})=>{
  const closePopref =useRef(null)
  const {quoteBookList} =useSelector((state) => state.globalreducer)
  const dispatch = useDispatch();

  // const {deleteQuotation}=useAddItems()
  useOutsideClick(closePopref,()=>{
    dispatch(setActionMode(null))
    closePopup();
  })



  return(
      <div className='delete_item_popup' ref={closePopref}>
        <p>Remove item from your quotation</p>
        <TableLayoutNew lists={quoteBookList} total={10} closePopup={closePopup}/>
      </div>
  )
}

export const ExportPdfPopup =({data,closePopup,typePdf})=>{

  const closePopref =useRef(null)
  const {quoteBookList} =useSelector((state) => state.globalreducer)
  const dispatch = useDispatch();
  dispatch(setShowMoreTimeLine(true))
  useOutsideClick(closePopref,()=>{
    dispatch(setActionMode(null))
    closePopup();
  })



  function toggleElementDisplay(selector, displayState) {
    const element = document.querySelector(selector);
    if (element) {
      const initialDisplay = getComputedStyle(element).display;
      element.style.display = displayState;
      return { element, initialDisplay };
    }
    return null;
  }

  function restoreElementDisplay({ element, initialDisplay }) {
    if (element) {
      element.style.display = initialDisplay;
    }
  }
  
  function convertToPdf() {

    // Save initial display states for elements that need to be hidden
    const back_itemsState = toggleElementDisplay('.back_items', 'none');
    const showMore = toggleElementDisplay('.show-less-container', 'none');
    const dropdownState = toggleElementDisplay('.dropdownContainer', 'none');
    const accomHeadingState = toggleElementDisplay('.accom-Heading', 'none');
    const detailState = toggleElementDisplay('.quote-detail-parent', 'none');
    const saveBtn = toggleElementDisplay('.save_quotaion_button', 'none');
    const totalPrice = toggleElementDisplay('.dashed-total', 'none');
    const totalPricePdf = toggleElementDisplay('.dashed-totalPdf', 'block');
    // Sauvegarder l'état initial de la classe "body-scroll"
    const element = document.querySelector('.body-scroll'); element?.classList.remove('body-scroll');


    // Exporter en PDF
    exportToPDF("toPdf").then(() => {
      dispatch(setShowMoreTimeLine(false))
      // Restaurer les états d'affichage initiaux des éléments
      element?.classList.add('body-scroll'); 
      if (showMore) restoreElementDisplay(showMore);
      if (totalPrice) restoreElementDisplay(totalPrice);
      if (totalPricePdf) restoreElementDisplay(totalPricePdf);
      if (back_itemsState) restoreElementDisplay(back_itemsState);
      if (dropdownState) restoreElementDisplay(dropdownState);
      if (accomHeadingState) restoreElementDisplay(accomHeadingState);
      if (detailState) restoreElementDisplay(detailState);
      if (saveBtn) restoreElementDisplay(saveBtn);

      // Close the popup after PDF export
      closePopup();
    });
  }

  return(
      <div className='exportpdf-parent' ref={closePopref}>
        <p>Export quotation</p>
        <p>Your quotation will be generate in PDF Format</p>
        <button onClick={()=>convertToPdf()}>Export quotation</button>
      </div>
  )
}

export const QuotationSendMail =({closePopup})=>{
  const closePopref =useRef(null)
  const [mailContent, setmailContent] = useState("Dear Sir/Madam, \n \nPlease find your booking for [Name] attached herewith.\nWe remain at your disposal for any questions or further clarification.\n \nKind regards,\nThe Mautourco Team");
  useOutsideClick(closePopref,()=>closePopup()) // outside click

  const handleEmailContentChange = (event) => {
    setmailContent(event.target.value); // Step 3: Update state
  };
  
  const getPdf=()=>{

  }
  getPdf()
  const label ="Include items technical sheet"

  return(
      <div className='quotation-mail-popup' ref={closePopref}>
        <p>Send quotation via e-mail</p>
        <form>
            <input type="text" placeholder='Recipient*' required/>
            <input type="text" placeholder='Subject' required/>
            <textarea 
                name="emailContent" 
                placeholder="Write your email here..." 
                rows="10" 
                cols="30"
                value={mailContent}
                onChange={handleEmailContentChange}
            >
            </textarea>

            <div>pdf attach</div>
            <div className='button-block'>
              {/* <label><input type="checkbox" name="subscription" /> {label}</label> */}
              <div><button type="submit" >Send</button></div>
            </div>
        </form>
      </div>
  )
}




