import React,{useState,useEffect} from 'react';
import cotationclose from "../../Assets/images/close_cotation1.png"
import QuoteInputs from '../Quotations/QuoteInputs';
import useCreateQuote from '../Quotations/hooks/useCreateQuote.js';
import {lengthOne,deleteRow} from "../../store/transferReducer.js"
import { useSelector,useDispatch } from "react-redux";
import {checkDelete} from "../../store/errorReducer.js"
import { useNavigate } from 'react-router-dom';
import { buildPayloadQuotation, capitalizeFirstLetters,getBeforeUnderscore } from '../../utility/globalFunc.js';
import useQuotationService from '../../hooks/useQuotationService.js';
import CircularProgress from '@mui/material/CircularProgress';
import { AIRPORT_LOCATION_NAME } from '../../constants.js';

export function SaveCotationInputs({closepopup,phase,notsaved = false}) {
    const [popupopen,setpopupOpen]=useState({succespop:false,popstatus:null,quotation_screen:false})
    const {agency_name,first_name,last_name,agency_id,user_name,user_id,currency,payment_term} =useSelector((state) => state.authpath.userData); 
    const {transferList,refCode,regionDetails,paxData,transferPax} =useSelector((state) => state.transfer_Global)
    const [cotationDetails, setCotationDetails] = useState({first_name:"",last_name:"",agency:"",Cotation_name:refCode});
    const {selExcursion} =useSelector((state) => state.excursionreducer)
    const {selRooms,roomPax} =useSelector((state) => state.accomodationreducer)
    const {sortedList,actionMode} =useSelector((state) => state.globalreducer)  

    const {createquote,status,loading} = useCreateQuote();
    const [fadeClass,setFadeClass]=useState("");
    const [isnotsavedQuotation,setisnotsavedQuotation] = useState(false)
    const [errorMessage,setErrorMessage]=useState("");
    const {fetchQuotationData}=useQuotationService();
     
    const navigate = useNavigate();
    const QUOTEPOPUP_INPUTS = [  
        {name:"Agent First_name",labName:"Agent Forename*",id:1,value:first_name},
        {name:"Agent Last_name",labName:"Agent Last name*",id:2,value:last_name},
        {name:"Agency",labName:"Agency",id:3,value:agency_name},
        {name:"Cotation_name",labName:"Quotation name",placeholder:"Please input your client's name",id:4,className:"quotation-name",value:""},
    ]   

    const onUpdateField = e => {
       if(e.target.value.includes("_")){
       
       }
        else{
            const nextFormState = {
                ...cotationDetails,
                [e.target.name]: e.target.value,
                };
            setCotationDetails(nextFormState);
        }; 

        }
       

    const onSubmitForm = async e => {
        e.preventDefault();
        if(notsaved){
            setisnotsavedQuotation(true)
        }
        let sendObject=buildPayloadQuotation(transferList,selExcursion,selRooms,cotationDetails.Cotation_name,first_name,last_name,roomPax,actionMode);
        
       await createquote(sendObject,setErrorMessage);
    };
    
    useEffect(() => {
        if (status === 201 && notsaved) {
            openClientPage();
        }
    }, [isnotsavedQuotation,status]);

    const closeQuote = (e)=>{
        setTransferDataG((prevstate) => ({
            ...prevstate,
            Savecot_popup:false,
            sentClient_details:true
          }));
    }

    const openGridQuote =(e)=>{
        e.preventDefault();
        closepopup();
        navigate("/quotation");
        
    }

    const openClientPage=(e)=>{
        e.preventDefault();
        fetchQuotationData(refCode,(res) => {
            let accomTotal = 0;
            let result =res.data.result;
            console.log("result:",result);
            navigate("/userdetails",{state:{service:result,refCode:refCode}});
            //dispatch(totalList({folder:"",services:result}))


        },(err) => {
            alert(err);
            console.log("error",err);
            
        });
    }

    const handleOpenPopup=(e,isClosed)=>{
        e?.preventDefault();
        if (!isClosed) {   
            setFadeClass("fade-out-200");
            setTimeout(() => {
                setFadeClass("");
                closepopup(isClosed);
            }, 200);
        }else{
            closepopup(isClosed);
        }
    }

    const getClientNameValue=(data)=>{
        // if (data.name === "Cotation_name" && refCode === null) {
            // return data.value;
        // }
        // if (data.name !== "Cotation_name") {
        return data.value;
        // }
        // return refCode;
    }

    return (
        
          
            <div className={`${popupopen.succespop ?"save_input_popup":"save_input_popup"} ${fadeClass}`}>
                <div onClick={(e)=>{ handleOpenPopup(e,false)}} className='close-button'></div>
                <div className={popupopen.quotation_screen ? "hide":"save_input_head"}>
                    <div className="save_head_name">Save quotation</div>
                </div>
                
                <div className={popupopen.succespop ||  popupopen.quotation_screen ?"hide":'active'}>
                    <form onSubmit={(e)=>{onSubmitForm(e)}} autoComplete="off">
                        <div className='save-parent-input'>
                            {
                                QUOTEPOPUP_INPUTS.map((data,ind)=>{
                                    return(
                                        <div style={{ width: '297px' }} key={ind}>
                                            <p className='label'>{data.labName}</p>
                                            <input 
                                                type="text" 
                                                placeholder={data.placeholder||data.labName} 
                                                name={data.name}
                                                value={data.value === "" ? cotationDetails.Cotation_name||"":  getClientNameValue(data)||""}
                                                style={{ width: data.id === 4 ? "626px" : "", color: data.id !== 4 ?"#adabab":""}}    
                                                onChange={onUpdateField}
                                                className={`${data.className?(data.className+` ${errorMessage?"error":""}`):""}`}
                                                required
                                                readOnly={ind < 3}
                                            />
                                            
                                        </div>
                                    )
                                })
                            }   
                        </div>
                     
                        <div className='savebtn_cot_btnblock'>
                            {/* <button className='savebtn_cot'><span>{notsaved ? 'Next' : 'Save quotation'}</span></button> */}
                            <button className='savebtn_cot'>
                                <span>{notsaved ? 'Next' : 'Save quotation'}</span>
                                {loading && (
                                    <CircularProgress
                                    color="secondary"
                                    style={{ width: "20px", height: "20px", color: "#fff", marginLeft: "8px" }}
                                    />
                                )}
                            </button>
                        </div>
                    </form>
                    {errorMessage&&<p className='error-message'>Attention: {errorMessage}</p>}
                </div>  
                
                <div className={status !== null ?'success_cotpopup fade-in-200':"hide"}>
                    <div>
                        { status === 201 ?
                        
                        (<div className='success_popchild'>
                            <div >Your quotation has been successfully saved !<br/><span>"{getBeforeUnderscore(refCode)}"</span><span> quotation</span></div>
                            <div className='success_popchild_btns'>
                                <button onClick={(e)=>{openGridQuote(e)}}>View on quotation grid</button>
                                <button onClick={(e)=>{openClientPage(e)}}>Book now</button>
                            </div>
                        </div>):""
                        
                        }
                    </div>      
                </div>  

                <div>
                    { 
                    popupopen.quotation_screen ?
                        <QuoteInputs 
                            paxdata={paxData}   
                            agency_id={Number(agency_id)}
                            to_ref_code={refCode}
                            fromDate={transferList[0].TransferDate}
                            toDate={transferList[transferList.length -1].TransferDate}
                            closeQuote={closeQuote}
                        />
                        :<></>
                    }
                </div>              

            </div>
            
        
    );
}

/////////////////////////////////////////////////////// SelectedLocation from dropdowns starts....................................
export function SelectedLocation({ seldata, removefunc }){
  
    return(
        <div className='selected_location'>
            <div className='slectedLocation_head'>
                <div>You Selected: </div>
                <div className='remove_sel' onClick={(e)=>{removefunc(e)}}>Remove selection</div>
            </div>
            <div className={'slectedLocation_body '+(seldata !== AIRPORT_LOCATION_NAME ? "locationBack":"" ) }>
                <div>{capitalizeFirstLetters(seldata)} <br/> <span>{"No Information Available"}</span></div>
            </div>
        </div>
    )
}

/////////////////////////////////////////////////////// DeletePopup starts....................................
export function DeletePopup({setSearchClick,setpopupclose}){

    const {transferUpdates} =useSelector((state) => state.transfer_Global) 
    const {isActive:delStatus,delData} = useSelector((state) => state.errorreducer.delete)
   
    const dispatch = useDispatch();
  
    const deletefunc =(e,name)=>{
        e.preventDefault()
        if(delStatus && name === "cancel"){
            let sendObj = {delbtn:true,delpop:false}
            dispatch(checkDelete(sendObj))  
        }
        if(delStatus && name === "delete"){    
          if (transferUpdates.length === 1) {
            let sendObj = {delbtn:false,delpop:false}
            dispatch(checkDelete(sendObj)) 
            setSearchClick(false)
            dispatch(lengthOne())
            setpopupclose(true);
           
    
          } else {
            dispatch(deleteRow(delData.IdTransfer))
            let sendObj = {delbtn:true,delpop:false}
            dispatch(checkDelete(sendObj)) 
            setSearchClick(false)
            setpopupclose(true);
           
          }
        }
    }
   
    return(
        <>
            <div className='deletepopup_parent'>
                <div className='deletepopup_text'>Are you sure to delete this transfer ? </div>
                <div className='popup_inputs'>
                    <div className='inputbox popup_inputs_calandar'>Transfer date <br/><span>{delData.TransferDate}</span></div>
                    <div className={'inputbox popup_inputs_calandar'}>Location From<br/> <span>{delData.LocationFromName}</span></div>
                    <div className='inputbox popup_inputs_calandar'>Location To<br/> <span>{delData.LocationToName}</span></div>
                </div>
                <div className='deletepopup_button'>
                    <div onClick={(e)=>{deletefunc(e,"cancel")}}>Cancel</div>
                    <button onClick={(e)=>{deletefunc(e,"delete")}}>Confirm</button>
                </div>
            </div>
            {/* {isOpen ? getPopupContent():<></>}  */}
        </>
    )
}

export function Errorpopup(){
    return(
        <div className='error_popup_parent'>
            <div className='error_popup_content'>
            Transfer line has been delete 👍
            </div>
        </div>
    )
}
