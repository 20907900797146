import { useState } from 'react';
import {axiosRequestBody} from '../services/apiClient.js';
import {BOOKING_ENDPOINT} from "../services/apiEndpoints.js"
import { useNavigate } from 'react-router-dom';
import { AIRPORT_LOCATION_NAME, DEFAULT_DATE_FORMAT, ISO_8601_DATE_FORMAT, TIMEOUT_DURATION } from '../constants.js';
import {  checkNotEmpty,backenedDates,generateUniqueRefId, isServiceAccomodation, isServiceTransfer, formatStrDate, getDateFromString, isServiceExcursion, getClientTypeByAcronyme } from '../utility/globalFunc.js';
import { format, isValid } from 'date-fns';

// Custom hook to fetch data from an API
const useBookingService = () => {
    const [loading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [bookingData,setBookingData]=useState(null);
    const navigate = useNavigate();

    const validateData=(formData)=>{
        let statusInput = {
            indiceError : [false, false],
            statusStyle: ['success','success']
        }; 
        const element = formData[0];
        if (!checkNotEmpty(element.first_name)) {
            statusInput.indiceError[0] = true; 
            statusInput.statusStyle[0] = 'error';  
        }
        if (!checkNotEmpty(element.last_name)) {
            statusInput.indiceError[1] = true; 
            statusInput.statusStyle[1] = 'error'; 
        }
        return statusInput;

    }

    function buildAccomStructure(accom) {
        return accom.Rooms.map((room,ind) => {
            let formattedDate = backenedDates(room.Dates)

            return {
                "hotelId": accom.HotelId,
                "servicePaidBy": "agency",
                "checkinDate": formattedDate[0],
                "checkoutDate": formattedDate[1],
                "allot": "Out Allot",
                "roomDetailsId": room.room_detail_id || room.RoomDetailId,
                "mealsupplement": room.Mealsupplement || room.MealPlanId, // need to add 
                "quotationPrice": room.quotationPrice ? room.quotationPrice.toString() : room.Total.toString(),
                "clientCategory": getClientTypeByAcronyme(room.ClientType || room.ClientCategory), // need to add
                "clientRefs": []
            }
        })
    }

    function buildTransferStructure(transfer){
        console.log("buildTransferStructure",transfer);
        
        let { OtherServices,LocationFromId, LocationToId, TransferDate, TransferType, Flight, Remarks, TransferTime, TransferCategoryId, VehicleTypeId,Currency,luggage} = transfer
        console.log("otherServices",OtherServices);


        
        return {
            "currency": Currency,
            //"transferType": "FIT",
            "transferType": TransferType,
            "idLocationFrom": LocationFromId,
            "idLocationTo": LocationToId,
            "transferDate": formatStrDate(TransferDate,DEFAULT_DATE_FORMAT,ISO_8601_DATE_FORMAT),
            "flight": Flight||"",
            "remarks": Remarks||"",
            "transferTime": TransferTime,
            "idTransferCategory": TransferCategoryId,
            "idVehicleType": VehicleTypeId,
            "clientCategory": "standard",
            "luggage":luggage,
            "clientRefs": [],
            "otherServices":OtherServices

        }
    }

    function buildExcursionStructure(excursion){
        const {data,location,vehicleType,excursion_name,price,...rest}=excursion
        return rest;
    }

    const getBookingServicePayload=(services)=>{

        console.log("services",services);
        
        const trans_result=[];
        const excursion_result=[];
        const accomm_result=[];
        
        for (const service of services) {
            if (isServiceAccomodation(service)) {
                accomm_result.push(...buildAccomStructure(service));
            }
            if (isServiceTransfer(service)) {
                trans_result.push(buildTransferStructure(service));
            }
            if (isServiceExcursion(service)) {                
                excursion_result.push(buildExcursionStructure(service));
            }
        }
        return {
            "reservationTransfer":trans_result, 
            "reservationExcursion":excursion_result,
            "reservationAccom":accomm_result,
            "reservationOthers":[]
        }

    }

    function buildPaxInformation(formState){
        return formState.map((data)=>{
            return {
                "title": "Mr",
                "surName": data.last_name === "" ? "N/A" : data.last_name,
                "name": data.first_name === "" ? "N/A" : data.first_name,
                "remarks": data.remarks === "" ?"N/A":data.remarks,
                "language": "5",
                "client_toref": data.id,
                "age": data.age
            }
        })
    }

    function getTransferHotelId(transfer){
        return transfer.LocationFromName===AIRPORT_LOCATION_NAME?transfer.LocationToId:transfer.LocationFromId;
    }

    function getAccomHotelId(accom){
        return accom.HotelId;
    }

    function getHotelId(services){
        if (!services||services.length===0) {
            throw new Error("No services selected");
        }
        const firstService=services[0]
        if (isServiceTransfer(firstService)) {
            return getTransferHotelId(firstService);
        }
        if (isServiceAccomodation(firstService)) {
            return getAccomHotelId(firstService);
        }

    }

    // PAYLOAD OF FINAL BOOKING
    const getBookingData=(services,dateRange,formState,refCode, agencyId)=>{
        let clients = buildPaxInformation(formState);
        
        const bookingPayload={
            "folder":{
                //"businessPartnerRef": refCode ? refCode :formState[0].first_name +generateUniqueRefId(),
                "businessPartnerRef": null,
                "bookingAgencyId": agencyId,
                "dossierName": formState[0].first_name,
                "serviceType": "FIT",
                "idHotel": getHotelId(services),
                "dateFrom": formatStrDate(dateRange[0]||dateRange.dateStart,DEFAULT_DATE_FORMAT,ISO_8601_DATE_FORMAT),
                "dateTo": formatStrDate(dateRange[1]||dateRange.dateEnd,DEFAULT_DATE_FORMAT,ISO_8601_DATE_FORMAT),
                "remarks": "N/A",
                "clients": clients
            },
            "services":getBookingServicePayload(services)
        };

        return bookingPayload;
    }
    
    const bookingService = async (payload) => {
        setLoading(true);
        
        try {
            const axiosConfig = { timeout: TIMEOUT_DURATION }; 
            const refCode = await axiosRequestBody("POST",BOOKING_ENDPOINT,payload,axiosConfig);
            setBookingData(refCode)
            setIsSuccess(true)
        } catch (error) {
               console.log(error);
               setBookingData(null)
                if(error.response && error.response.status === 401){
                    navigate("/")
                }

                else if(error.response && error.response.status === 409){
                    //setIsSuccess(true)
                    alert(error.response.data.message)
                    setIsSuccess(false)
                }
            
                
                else {
                    alert(error.response.data.message)
                    setIsSuccess(false)
                }
               
        } finally {
            setLoading(false);
        }
    };   
    
     
    
    function getServiceDateStart(service){
        if (isServiceAccomodation(service)) {
            return service.Dates?service.Dates[0]:service.Rooms[0].Dates[0];
        }
        if (isServiceTransfer(service)) {
            return service.TransferDate;
        }
        return "";
    }
  
    function getServiceDateEnd(service){
        if (isServiceAccomodation(service)) {
            return service.Dates?service.Dates[1]:service.Rooms[0].Dates[1];
        }
        if (isServiceTransfer(service)) {
            return service.TransferDate;
        }
        return "";
    }
  
    function getJourneyDateRange(services){
        
        const dateRange=services.reduce((range,service)=>{
            const serviceStartDate=getServiceDateStart(service);
            const serviceEndDate=getServiceDateEnd(service);
  
            let startDate = getDateFromString(serviceStartDate,ISO_8601_DATE_FORMAT);
            if(!isValid(startDate)) startDate=getDateFromString(serviceStartDate,DEFAULT_DATE_FORMAT);
  
            let endDate = getDateFromString(serviceEndDate,ISO_8601_DATE_FORMAT);
            if(!isValid(endDate)) endDate=getDateFromString(serviceEndDate,DEFAULT_DATE_FORMAT);
  
            if (!range.min || startDate<range.min) {
                range.min=startDate;
            }
            if (!range.max || endDate>range.max) {
                range.max=endDate;
            }
            return range;
        },{min:undefined,max:undefined});
        
        return {
            dateStart:dateRange.min?format(dateRange.min,DEFAULT_DATE_FORMAT):"",
            dateEnd:dateRange.max?format(dateRange.max,DEFAULT_DATE_FORMAT):""
        };
    }
   
    return { loading,isSuccess,bookingService,validateData,getBookingData,bookingData,setBookingData,getJourneyDateRange };
};


export default useBookingService;