import React from 'react'
import { separateurMillier } from '../../utility/globalFunc'
import { CarDetails, Supplement } from '../MiniComponents/Atom'
import { DisplayPax } from '../Excursion/ExcursionArray'
import { useSelector } from 'react-redux'
import { TRANSFER_VEHICLE_TYPE } from '../../constants'

export default function TransferCarList({ visitMode, data, passId, ind, noClick,selectedCar }) {
    const {  updatePax,tempId: currentTransferId } = useSelector((state) => state.transfer_Global);
    const { AdultCount: Adult=0, ChildCount: Child=0, TeenCount: Teen=0, InfantCount: Infant=0 } = useSelector((state) => state.transfer_Global.transferUpdates[currentTransferId] || {});

    const totalPax = Adult + Child + Teen + Infant;
    let errtxt1 = "Baby seat is not applicable for this vehicle"
    let errtxt2 = "There’s no Infant or Child in your pax selection"

    const getUnits=()=>{
        // if (data.TransferType===TRANSFER_VEHICLE_TYPE.PAX) {
            return totalPax;
        // }
        // return Math.ceil(totalPax/data.MaxPax);
    }
    // let errtxt3 = "Supplement is not available for this vehicle"
    
  return (
        <div style={{
              animation: "shimmer 1.5s infinite"
          }}>
            <div style={{ position: "relative" }}>
                <img src={data.Pic_url} alt="car img" className="carInfo-img" />
            </div>

            <div className="display-car-data">
                <div className="details-block">
                    <CarDetails carInfo={data} />
                </div>

                <div className='sup-hdtxt'>Supplement <span>{data.MaxAllowedBabySeat === 0 ? errtxt1 : (Infant === 0 && Child === 0) ? errtxt2 : ""}</span></div>

                <div className={"supplement-block " + (data.MaxAllowedBabySeat === 0 || Infant === 0 && Child === 0 || data.status !== null ? "disable" : "")}>
                    <Supplement data={data} name="Baby Seats" supmod={1} notes={false} lugtype="" detailId={passId} regionId={ind} />
                </div>
                <div className={"supplement-block " + (!data.LuggageCar || data.LuggageCar?.capacity === 0 || data.status !== null ? "disable" : "")}>
                    <Supplement data={data} name="Luggage Car" supmod={2} notes={true} lugtype="Cars" detailId={passId} regionId={ind} />
                </div>
                <div className={"supplement-block " + (!data.LuggageTruck || data.LuggageTruck?.capacity === 0 || data.status !== null ? "disable" : "")}>
                    <Supplement data={data} name="Luggage Truck" supmod={2} notes={true} lugtype="Trucks" detailId={passId} regionId={ind} />
                </div>

              <div className={'sup-notes ' + (data.TransferType !== "Trip" ? "hidden" : "")} style={visitMode ? { display: 'none' } : {}}>
                    *Note : The number of vehicles will depend on the number of pax
                    {/* <p>Base Price: {data.tariff_type === "Trip" ? data.base_price +".00":"" }</p> */}
                    <p>Base Price: {data.TransferType === "Trip" ? separateurMillier(data.AdultPrice) + ".00" : ""}</p>
                </div>
                {visitMode &&
                <div className='info-message-visit'>
                      <p className='notes'>Caution : Please input transfer date in order to get more <br/>details on vehicles</p>
                </div>
                }
                
              <div className="btnblock_carList" style={visitMode ? { marginTop: '-0.5px' } : {}}>
                    <DisplayPax visitMode={visitMode} totalpax={[updatePax]} unit={getUnits()} list={data} price={data.final_price} currency={data.Currency} cname={2} />
                    <button
                        id='selectedBtn'
                        className={"carselBtn " + (data.status === null && noClick ? "" : data.status === "selected" ? "seldiv " : "notseldiv")}
                        onClick={(e) => { selectedCar(e, data, ind, passId) }}
                    >   
                        {visitMode ? <span>--</span>:<span>{data.status === null ? "Add to quote" : data.status === "selected" ? "Selected" : "--"}</span>}
                    </button>
                </div>
            </div>
        </div> 

  )
}
