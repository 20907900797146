import React,{useEffect} from 'react';
import SameInputs from "../Excursion/SameInputs";
import { useSelector,useDispatch } from 'react-redux';
import {addLists,clearTransfer, setIsSameVehicle} from "../../store/transferReducer.js"
import useCarList from '../../hooks/useCarlist.js';
import Skeleton from '@mui/material/Skeleton';
import { useNavigate } from 'react-router-dom';
import{TRANSFER_INPUT_OBJECT} from "../../constants.js"
import { scrollToSection, scrollToSectionv1,formatDateString } from '../../utility/globalFunc.js';



function ButtonBlock({addTransfer,delbtn,label,deleteItemClick,setSearchClick,errchk}) {
  
    const {transferUpdates,loading,isSameVehicle,isResultSameVehicle}=useSelector((state) => state.transfer_Global)
    const {currency:mtcoCurrency}=useSelector((state) => state.authpath.userData)
    const {subAgencyAdded}=useSelector((state) => state.authpath)


   
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {fetchDataTransfer,error } = useCarList();
   
   
    const addInputBoxes =(e)=>{
        e.preventDefault(); 
        let objectLength = transferUpdates.length +1;
        let addInputs = { ...TRANSFER_INPUT_OBJECT };
        addInputs.IdTransfer = objectLength;
        dispatch(addLists({addInputs,index:transferUpdates.length}))
    }

    const getTransferPayload=()=>{
        if (isSameVehicle) {
            return transferUpdates;
        }
        if (isSameVehicle!=isResultSameVehicle) {
            return transferUpdates;
        }
        return transferUpdates.filter(item=>!item.IsSearched);
    }

    const searchData = (e)=>{
        e.preventDefault();
        const newTransfers=getTransferPayload();
        const result =  newTransfers.map(item => ({
            idLocationFrom: item.LocationFromId ? item.LocationFromId:"",
            idLocationTo: item.LocationToId ? item.LocationToId:"",
            transferDate: item?.TransferDate ? formatDateString(item?.TransferDate,'dd/MM/yyyy', 'yyyy-MM-dd'):"",
        }));;

        const transfersIdNotSearched=newTransfers.map(transfer=>transfer.IdTransfer);
        let {currency:agencyCurrency} =subAgencyAdded;
        console.log("!!Object.entries(subAgencyAdded).length",!!Object.entries(subAgencyAdded).length);
        
        const sendObject = { 
            payload:{
                currency:!Object.entries(subAgencyAdded).length ? mtcoCurrency :agencyCurrency,
                use_same_vehicle:isSameVehicle,
                transfer_data: result,
            },
            list:newTransfers,
            transfersIdNotSearched
        };
        console.log({sendObject});
        
        
        const hasEmptyString = result.some(item => Object.values(item).some(value => value === ""));

        if (hasEmptyString || result.length === 0 ) {
            setSearchClick(true) 
        } 
          
        else {
            setSearchClick(false)
            fetchDataTransfer(sendObject).then(rep=>{
                scrollToSectionv1('pickCarsText', 100);
                navigate("/select-vehicle")
            })
        }
    }

    const onSameVehicleChange=(isChecked)=>{
        dispatch(setIsSameVehicle(isChecked));
    }

    const isSearchDisabled=()=>{
        const notSearchedTransfer=transferUpdates.filter(transfer=>!transfer.IsSearched);
        const isSameVehicleResult=isSameVehicle===isResultSameVehicle;
        return !notSearchedTransfer.length&&isSameVehicleResult;
    }
    return (
        <>
        <div className="buttonBlock" style={{marginTop:transferUpdates.length >3 ? "20px":""}}>
             <div className="errormsg ">{errchk ? errchk.message:""}</div>
            { !delbtn ?
                
            <div className="buttonBlock_select">
                {errchk ? <p className='buttonBlock_select_p'>Please fill the Transfer</p>:<></>}
               {!loading ? <SameInputs label={label} type="checkbox" data={isSameVehicle} onchange={onSameVehicleChange}/>:<Skeleton/>}
                <div className="buttonBlockRight">
                   {!loading ? <>{addTransfer ?<button  className="addTransferBtn"  onClick={(e) => { addInputBoxes(e)}}  id="addTransfer_btn" >Add a Transfer</button>:<></>}</>:<Skeleton width={100}/>}
                   {!loading ? <button className={`searchBtn ${isSearchDisabled()?"disable":""}`}  onClick={isSearchDisabled()?undefined:(e) => {searchData(e) }} id="searchBtn">Search</button>:<Skeleton width={100}/>}
                </div>
            </div> 
            : <div className="buttonBlock_delete">
                    <button className="deleteAll"  onClick={(e) => {deleteItemClick(e,"close") }} id="delallBtn">Done</button>
                </div> 
              }
        </div>
        
        </>
    );
}

export default ButtonBlock;