import { useState } from 'react';
import { HOTELDETAILS_ENDPOINT, HOTELAMENITIES_ENDPOINT, HOTEL_ROOMS_ENDPOINT, HOTELREGION_ENDPOINT, HOTELLIST_ENDPOINT  } from '../../../services/apiEndpoints.js';
import { axiosRequestBody, axiosRequestParams,axiospagination } from '../../../services/apiClient.js';
import { useDispatch,useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {createRoom,isRoomLoading,selHotel,accomPax,setAccomLoading, setPageLoading, resetCreateRoom, setPendingRooms, setSuggestionLoading, setHotelSuggestions, resetPendingRooms} from "../../../store/accomodationReducer.js"
import { amenitiesDetails, DEFAULT_DATE_FORMAT, ISO_8601_DATE_FORMAT, TIMEOUT_DURATION } from '../../../constants.js';
import { formatDateString,addPatternToResults, getRoomsGroupKey, getAccomInputObject } from '../../../utility/globalFunc.js';

// Custom hook to fetch data from an API
const useHotelDetails = () => {
    const [loading, setLoading] = useState(false);
    const [errormsg, setErrorMsg] = useState({content:false,rates:"",suggestion:""});  
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {roomPax,calendarDate,selectedHotel} = useSelector((state) => state.accomodationreducer);
    const {sortedList} = useSelector((state) => state.globalreducer);
    const location=useLocation();
    const [roomLoading,setRoomLoading]=useState(false);

    const fetchHotelDetails = async (payload,backPath) => {
        // setLoading(true)
        let paramLoading = { status: true, isFirstFlow: false };
        dispatch(setAccomLoading(true));
        dispatch(setPageLoading(paramLoading));
        console.log("payload",payload);
        try {
            const axiosConfig = { timeout: 15000 }; 
            const response = await axiosRequestParams("GET",HOTELDETAILS_ENDPOINT,{hotel_id:payload.hotel_id},axiosConfig);            
            let {HotelLocation,HotelID,HotelRegion,HotelName,hotel_rooms} = response.data.result;
            // dispatch(clearRoom())
            // dispatch(createRoom(Object.values(hotel_rooms)))
            dispatch(selHotel({loc_det:HotelLocation,loc_id:HotelID,HotelRegion,loc_name:HotelName}))
            navigate("/selected-accomodation",{state:{result:response.data.result,backPage:backPath}});
        } catch (error) {
            console.log("error response",error);
                if(error.response && error.response.status === 401){
                    navigate("/")
                }
                else if (error.response && error.response.status === 404) {
                    console.log("error response, 404",error);
                    alert("chk: ",error.response.data.message);
                    // dispatch(accomPax([{"adult":1,"teen":0,"child":0,"infant":0,"room_id":1,"paxAge":[],clientType:["Standard client","STD"]}]))
                }
                else if (error.request) {
                    console.log("error response",error);
                    alert("chk11: ",error.message);
                } 
                
                else {
                    navigate("/internal-error",{state:{message:error.response.data.message,status:error.response.status}})
                }
        
        } finally {
            let paramLoading = { status: false, isFirstFlow: false }
            dispatch(setAccomLoading(false))
            setTimeout(() => {
                dispatch(setPageLoading(paramLoading));
            }, 2000);
        }
    };

    const fetchHotelRegionDetails=async(hotelId,setRegionData)=>{
        try {
            const axiosConfig={timeout:TIMEOUT_DURATION};
            const response=await axiosRequestBody("GET",`${HOTELREGION_ENDPOINT}?hotel_id=${hotelId}`,{},axiosConfig);
            setRegionData(response.data.result);
        } catch (error) {
            if(error.response && error.response.status === 401){
                navigate("/")
            }
            else if (error.response && error.response.status === 404) {
                console.log("error response, 404",error);
                alert(error.response.data.message);
            }
            else if (error.request) {
                console.log("error response",error);
                alert(error.message);
            } 
            
            else {
                navigate("/internal-error",{state:{message:error.response.data.message,status:error.response.status}})
            }
    
        }
    }

    const fetchHotelAmenities=async (hotelId,setHotelAmenities)=>{
        setLoading(true);
        try{
            const axiosConfig={timeout:TIMEOUT_DURATION};
            const response=await axiosRequestBody("GET",`${HOTELAMENITIES_ENDPOINT}?hotel_id=${hotelId}`,{},axiosConfig);
            setHotelAmenities(addPatternToResults(response.data.result));
        }catch(error){
            console.log("error response",error);
            if(error.response && error.response.status === 401){
                navigate("/")
            }
            else if (error.response && error.response.status === 404) {
                console.log("error response, 404",error);
                alert(error.response.data.message);
            }
            else if (error.request) {
                console.log("error response",error);
                alert(error.message);
            } 
            
            else {
                navigate("/internal-error",{state:{message:error.response?.data?.message||"",status:error.response?.status||""}})
            }
        }
    }
    
    function getAccomServiceGroup(date,hotelId){
        for (const accom of sortedList.services.reservationAccom) {
            if (JSON.stringify(accom.Dates)===JSON.stringify(date)&&accom.HotelId===hotelId) {
                return accom;
            }
        }
        return null;
    }

    const fetchHotelRooms=async (hotelId,date_from,date_to,pax,hotelName)=>{
        //setRoomLoading(true);
        dispatch(isRoomLoading({isLoading:true}))
        const body={
            "hotel_id":+hotelId,
            "date_from":date_from,
            "date_to":date_to,
            "clients":pax,
        }

        try {
           
            const result=await axiosRequestBody("POST",HOTEL_ROOMS_ENDPOINT,body);
            dispatch(isRoomLoading({isLoading:false}))
            dispatch(createRoom(result.data.result.RoomStays||[]));
            let nbRooms=pax.length;
            if (nbRooms>1) {
                let key=getRoomsGroupKey(calendarDate,hotelId);
                let acoomService=getAccomServiceGroup(calendarDate,hotelId);

                let nbExistingRooms = (acoomService ? acoomService.Rooms.length : 0)
                let nbPendingRooms = nbExistingRooms + nbRooms
                
                dispatch(setPendingRooms({
                    nbPendingRooms:nbPendingRooms,
                    nbNewRooms:nbRooms,
                    date:calendarDate,
                    hotelId:hotelId,
                    hotelName:hotelName||selectedHotel.loc_name,
                    key:key,
                    startRoomIndex:nbExistingRooms+1
                }))
                // dispatch(addEmptySelectedRoom({nbRooms:nbRooms,date:calendarDate,hotelId:hotelId,hotelName:selectedHotel.loc_name}));
            }else{
                dispatch(resetPendingRooms());
            }
        } catch (error) {
            dispatch(isRoomLoading({isLoading:false}))
            
            if (error.response && error.response.status === 404) {
                dispatch(isRoomLoading({errorMsg: error.response.data?.details || "No Rates Available"}))
            }
            else if (error.request) {
                dispatch(isRoomLoading({errorMsg: "No Response for the Rates"}))
            } 
            
            else {
                navigate("/internal-error",{state:{message:error.response.data.message,status:error.response.status}})
            }
        }


    }

    const fetchSuggestions=async(hotelId,date,clientsInfo,budgetRange,offset=0)=>{
        let payload=getAccomInputObject({dates:date,clientsInfo,hotelId,budgetRange,offset});
        console.log("payload",payload);
        dispatch(setSuggestionLoading(true));
        try {
            const axiosConfig = { timeout: TIMEOUT_DURATION };
            const response=await axiospagination("POST",HOTELLIST_ENDPOINT,payload,axiosConfig,1)
            
            let hotels=response.data.result.filter((hotel)=>{
                return hotel.HotelId!=hotelId
            });
            
            dispatch(setHotelSuggestions({suggestions:hotels}))
            
        } catch (error) {
            console.error(error);
            alert(error);
        }
        finally{
            dispatch(setSuggestionLoading(false));
        }
        
    }

    const fetchHotelData=async(payload,backPath,hotelName)=>{
        dispatch(createRoom([]));
        console.log("payload",payload);
        
        await Promise.all([
            fetchHotelDetails(payload,backPath),
            fetchHotelRooms(payload.hotel_id,payload.date_from,payload.date_to,payload.clients,hotelName),
            fetchSuggestions(payload.hotel_id,[payload.date_from,payload.date_to],payload.clients,payload.budget,payload.offset)
        ]);
    }
   
    return { errormsg, loading, fetchHotelDetails, fetchHotelRegionDetails,fetchHotelAmenities,fetchHotelRooms,fetchHotelData };
};


export default useHotelDetails;