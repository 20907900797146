import { useEffect, useState } from "react";
import { getBeforeUnderscore } from "../../../utility/globalFunc";

const useExportCSV = (quotelist = []) => {
    const [headerCSV,setHeaderCSV]=useState([]);
    const [dataCSV,setDataCSV]=useState([]);

    useEffect(()=>{
        setHeaderCSV(buildHeader());
        setDataCSV(buildDataCSV());
    },[quotelist])

    const buildHeader = () => {
        const headers = [{
            id: "file_nb",
            displayName: "file nb",
        }, {
            id: "file_name",
            displayName: "client name",
        }, {
            id: "status",
            displayName: "file status",
        }, {
            id: "client_type",
            displayName: "type",
        }, {
            id: "date_from",
            displayName: "from",
        },{
            id:"date_to",
            displayName:"to",
        },{
            id:"created_by",
            displayName:"created by",
        },{
            id:"agency_name",
            displayName:"agency name",
        },{
            id:"created_date",
            displayName:"created_date",
        }];
        return headers;
    }

    const buildDataCSV=()=>{
        const data=quotelist.map((quote)=>({
            ...quote,
            date_from:quote.date[0],
            date_to:quote.date[1],
            file_name:getBeforeUnderscore(quote.file_name)
        }));
        
        return data;
    }

    return {headerCSV,dataCSV}
}

export default useExportCSV;