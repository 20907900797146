
import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {
  paxData:{Adult:1,Teen:0,Child:0,Infant:0},
  pageName:"Transfer",
  childAge:null,
  totalPrice:null,

  sortedList:{
    folder:{},
    services:{
      reservationTransfer:[],
      reservationAccom:[],
      reservationExcursion:[],
      reservationOthers:[]
    }
  },

  quoteBookList:{},
  userDate:[],
  selectedQuoteInfo:null,
  docketData:[],
  actionMode:null,  
  userDateClients:[],
  dropDownPositionY:115,
  shwowDropDown:false,
  finalBookingData:{},
  showMoreTimeLine:false,
  showNotifDelete :false
}

export const globalUpdates = createSlice({
  name: 'globalreducer',
  initialState:INITIAL_STATE,
  reducers: { 
        // utiliser pour le pdf
        setShowMoreTimeLine(state, action) {
          state.showMoreTimeLine = action.payload;
        },
        paxDetails(state, action) {
          const { AdultPax,TeenPax,ChildPax,InfantPax } = action.payload;
          state.paxData ={ Adult:AdultPax, Teen:TeenPax, Child:ChildPax, Infant:InfantPax}
        },
       
        finabook(state, action) {
          state.finalBookingData = action.payload
        },

        addRefToBooking(state, action) {
          state.finalBookingData.folder.businessPartnerRef = action.payload
        },
       

        getPageName(state, action){
          state.pageName = action.payload
        },

        clearPax(state) {
          state.paxData = {Adult:1,Teen:0,Child:0,Infant:0}  
        },

        getChildAge(state, action){
          state.childAge = action.payload
        },

        getTotalPrice(state, action){
          state.totalPrice = action.payload
        },

        totalList(state, action){
          state.sortedList = action.payload
        },
        clearAll(){
          return INITIAL_STATE; 
        },
        selectedRef(state, action){
          state.selectedQuoteInfo = action.payload
        },
    
       
       /// Arrival dep date
        getUserDate(state, action){
          state.userDate = action.payload
        },
        getUserDateClients(state, action){
          state.userDateClients = [...state.userDateClients, action.payload];
        },
        getNumberOfUserDateClients(){
          return state.userDateClients.length;
        },
        setDropDownPositionY(state, action){
          state.dropDownPositionY = action.payload
        },
        setShowDropdown(state,action){
          state.shwowDropDown = action.payload
        },
        clearUserDate(state, action){
          state.userDate = []
        },

        docketInfo(state, action){
          state.docketData = action.payload
        },
        setActionMode(state,action){
          state.actionMode=action.payload;
        },
        resetActionMode(state,action){
          state.actionMode=null;
        },
        setReservationService: (state, action) => {
          const { serviceType, data } = action.payload;
          if (state.sortedList.services && state.sortedList.services[serviceType]) {
            state.sortedList.services[serviceType] = data;
          } else {
            console.error(`Service ${serviceType} is undefined in the state`);
          }
        },
        setQuoteBookList (state,action){
          state.quoteBookList= action.payload;
        },
        triggerNotifDelete(state,action){
          state.showNotifDelete = action.payload;
        } 
  },
})

export const {triggerNotifDelete,addRefToBooking,setShowMoreTimeLine,setQuoteBookList,setReservationService,finabook,getUserDate,docketInfo,clearUserDate,selectedRef,clearAll,totalList,getPageName,paxDetails,clearPax,getChildAge,getTotalPrice,setActionMode,resetActionMode,getUserDateClients,getNumberOfUserDateClients,setDropDownPositionY,setShowDropdown} = globalUpdates.actions

export default globalUpdates