import { useState } from 'react';
import axios from "axios"
import { useDispatch,useSelector } from 'react-redux';
import {getLoadingStatus,searchError} from "../store/errorReducer.js"
import {addListTransfer,loadStatus, setAllSearched, setIsResultSameVehicle} from "../store/transferReducer.js"
import { useNavigate } from 'react-router-dom';
import {VEHICLELIST_ENDPOINT} from "../services/apiEndpoints.js"
import {buildSameVehicleResult, transformRegionDetails} from "../utility/globalFunc.js"
import {TransferType} from "../constants.js"

// Custom hook to fetch data from an API
const useCarList = () => {
  const [error, setError] = useState(null);
  let token = localStorage.getItem("access-token");
  const {isSameVehicle}=useSelector((state)=>state.transfer_Global);
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const fetchDataTransfer = async (sendObject) => {
    dispatch(loadStatus(true))
    let {payload,list,transfersIdNotSearched} =sendObject
    

    try {
      const response = await axios.request({
        headers: {Authorization: `Bearer ${token}`},
        method: 'POST',
        url: process.env.REACT_APP_API_URL+VEHICLELIST_ENDPOINT,
        data:payload
      });
      
      let carlist = response.data.result
      if (isSameVehicle) {
        carlist=buildSameVehicleResult(carlist,payload.transfer_data.length);
      }
      
        const regionDetails = transformRegionDetails(carlist,list);
        const TransferType_added = list.map(item => ({
          ...item,
          TransferType: TransferType(item.LocationFromId, item.LocationToId, 1)
        }));
        console.log("regionDetails",regionDetails);
        
        const listPayload = {
          region_details:regionDetails,
          transDate:[],
          transfer_data:TransferType_added,
          idsSearched:transfersIdNotSearched
        }
         dispatch(addListTransfer(listPayload))
         dispatch(setAllSearched());
         dispatch(loadStatus(false));
         dispatch(setIsResultSameVehicle(payload.use_same_vehicle));

    } catch (error) {
      console.log("car list error response",error);
      if(error?.response?.status === 401){
        navigate("/")
      }
      else{
        setError(error.message)
        dispatch(searchError({type:"error",name:"transferSearch",message:error.message}))
        dispatch(loadStatus(false))
      }
        
    } finally {
        dispatch(getLoadingStatus(false))
        dispatch(loadStatus(false))
    }

  };

  return { error,fetchDataTransfer };
};


export default useCarList;