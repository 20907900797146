import { axiosRequestBody, axiosRequestParams } from '../services/apiClient';
import {QUOTATION_TYPE2} from "../constants"
import { QUOTELIST_ENDPOINT } from '../services/apiEndpoints';
import { useNavigate } from 'react-router-dom';

const useQuotationService = () => {
    const navigate=useNavigate();

    const fetchQuotationData=(refCode,successCallBack,errorCallback)=>{

        
        axiosRequestParams("GET",QUOTELIST_ENDPOINT,{ to_ref_code: refCode,quotation_type:QUOTATION_TYPE2 })
        .then(res=>{
            console.log(res);
            
            successCallBack&&successCallBack(res);
        }).catch((err)=>{
            errorCallback&&errorCallback(err);
        })
    }

    const fetchQuotationDataAndGoToBooking=(refCode)=>{
        fetchQuotationData(refCode,
        (res)=>{
            let sortedList;
                try {
                    sortedList = JSON.parse(res.data.reducer);
                } catch (error) {
                    console.error('Error parsing JSON:', error);
                    alert('An error occurred. Please try again.');
                    return;
                }
                if (sortedList && sortedList.selectedRooms && sortedList.selectedPax) {
                    navigate("/userdetails", { state: { rooms: sortedList.selectedRooms, pax: sortedList.selectedPax, refCode: refCode } });
                } else {
                    alert('Unexpected response format.');
                }
        },
        (err) => {
            alert(err.response?.data?.message||err.message);
        });
    }
  return {fetchQuotationData,fetchQuotationDataAndGoToBooking};
}

export default useQuotationService
