import React,{useState,useEffect} from 'react';
import { useSelector,useDispatch } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import usePopupData, { ADD_SERVICE_BOOKING, CANCEL_BOOKING } from '../hooks/usePopupData.js'; 
import PaxDetails from '../components/MiniComponents/PaxDetails'
import CancelationPolicy from '../components/Excursion/CancelationPolicy.js';
import QuoteCancellationLayout from '../components/Quotations/QuoteCancellationLayout.js';
import TableLayoutNew from "../components/MiniComponents/TableLayoutNew.js"
import NavbarBlock from '../components/Transfer/NavbarBlock.js';
import TimelineView from '../components/MiniComponents/TimelineView.js';
import logo from "../Assets/images/mautourco-logo.png"
import { properCase,getBeforeUnderscore } from '../utility/globalFunc.js';
import { totalList, triggerNotifDelete} from '../store/globalReducer.js';
import { BookingActionDropdown, QuotationActionDropdown } from '../components/Quotations/small_dropdowns.js';
import {SELECTED_ACCOM} from "../constants.js";
import { Back, NotifDeleteQuotation } from '../components/MiniComponents/Atom.js';
import CancelBooking from '../components/MiniComponents/CancelBooking.js';
// It was fully based on our globalreducer -> sortedList and totalPrice


function SelectedViewDetails(props) {
    const {agency_name,first_name,last_name,agency_id} =useSelector((state) => state.authpath.userData); 
    const {subAgencyAdded} =useSelector((state) => state.authpath);
    const {Adult,Child,Teen,Infant} =useSelector((state) => state.globalreducer.paxData)
    const { totalPrice, sortedList, quoteBookList, showNotifDelete } =useSelector((state) => state.globalreducer)
    const [popupbox,setpopupbox] = useState(false)
    const { isOpen, openPopup, closePopup, getPopupContent } = usePopupData();
    const navigate = useNavigate();
    const dispatch =useDispatch()
    const [popupOpen, setPopupOpen] = useState(false);
    const location=useLocation();
    const params=new URLSearchParams(location.search);
    const file_name=params.get('file_nb');
   

    let isSubAgencyHaveValue = Object.keys(subAgencyAdded).length > 0
    let totalClients = quoteBookList?.Clients?.length
    
    const getLabel=(item)=>{
        let label=item.client_status;
        return properCase(label);
      };

    const Booking=(e)=>{
        e.preventDefault();
        //let {services}=sortedList
        let {reservationTransfer,reservationAccom,reservationExcursion,refCode}=quoteBookList
        dispatch(totalList(quoteBookList));
        navigate("/userdetails",{state:{service:{reservationAccom,reservationTransfer,reservationExcursion},refCode:refCode}});
        //navigate("/userdetails",{state:{rooms:sortedList,pax:quoteBookList.refpax,refCode:quoteBookList.refCode}});  
    }

    const confirmSave=(e)=>{
        openPopup("info")
       setpopupbox(true)
    }

    const showFooter=()=>{
        return quoteBookList.refCode !== null && !quoteBookList.IdBooking
    }

    const isViewBooking=()=>{
        return quoteBookList.hasOwnProperty("IdBooking");
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if(showNotifDelete){
            setTimeout(() => {
                dispatch(triggerNotifDelete(false));
            }, 4000);
        }
    }, [showNotifDelete])
    return (
        <>
        <div className='view-quotationPage'>
               
                <div id="toPdf" className="save_quotaion_popup">
                <>
                {
                    showNotifDelete &&
                    <div className={`container-notif-delete ${!showNotifDelete ? 'hide' : ''}`}>
                        <NotifDeleteQuotation></NotifDeleteQuotation>
                    </div>
                } 
                    <div className="save_quotaion_head">
                        <div className="save_quotaion_headlft">
                        {/* <p className='save_quotaion_headlft_orange'>{quoteBookList.refCode }</p> */}
                        {isViewBooking()&&
                            <Back name={"quotation"}  label={ getBeforeUnderscore(file_name)+" Booking"}/>
                        } 
                        <p className='save_quotaion_headlft_orange'>
                            {!isViewBooking() ? (
                                <>
                                   <span className='save_quotaion_headlft_num'>File number: {file_name }</span>
                                    {getBeforeUnderscore(quoteBookList.refCode)}
                               
                                </>
                            ) : (
                                "Booking N° " + quoteBookList.IdBooking
                            )}
                        </p>
                            <p className='save_quotaion_headlft_name'>{first_name}  {last_name}, {agency_name}</p>
                           { !isViewBooking() && <div className='timeline-container'><TimelineView list={quoteBookList}/></div>}

                        </div>
                        <div className="save_quotaion_headryt">
                        {/* <div className={!isViewBooking() ? "save_quotaion_headryt" : "save_quotaion_headryt_booking"}> */}
                            {/* {!isViewBooking() ? <img src={logo}/> : null} */}
                            <img src={logo}/>
                            
                            <div className='cotDocdetails'>
                                <p>Document details</p>
                                {quoteBookList.refCode !== null ? <div><span className="label" style={{ marginTop: "0px" }}>File number:</span> <span style={{ marginTop: "0px" }} className="value">{file_name.substring(0,6)}</span></div> : <></>}
                                <div><span className="label">Agency:</span> <span className="value">{isSubAgencyHaveValue ? subAgencyAdded.agency_name: agency_name}</span></div>
                                <div><span className="label">Client-Type:</span> <span className="value">Standard</span></div>
                                <div><span className="label">Number of pax:</span> <span className="value">{totalClients}</span></div>
                            </div> 
                            <div className='dropdownContainer'>
                                <div className='buttonDrop' onClick={() => setPopupOpen(prev => !prev)}><label>More options</label></div>
                                    {/* popupOpen && <QuotationActionDropdown file_name={quoteBookList.refCode} setPopupOpen={setPopupOpen} isView={false} data={quoteBookList} isaddindService={isViewBooking() ? true : false} CancelBooking={CancelBooking} /> */}
                                {popupOpen && !isViewBooking() && <QuotationActionDropdown file_name={quoteBookList.refCode} setPopupOpen={setPopupOpen} isView={false} data={quoteBookList} isaddindService={isViewBooking() ? true : false} CancelBooking={CancelBooking}/>}
                                {popupOpen && isViewBooking() && <BookingActionDropdown booking={quoteBookList}/>}
                            </div>
                        </div>
                    </div>
                    
                    {isViewBooking()&&<div className={!isViewBooking() ?'absolute-added': 'absolute-added-booking'}>
                        <h3 className='title-recap-booking'>1. Client information</h3>
                        <div className='absolute-added-booking-client-info'>
                        {isViewBooking() && quoteBookList.Clients.map((pax,ind)=>{
                            let label=getLabel(pax);
                            return (
                                <div className='absolute-added-booking-client-info-container' key={ind+"paxdetails"}>
                              <PaxDetails firstName={pax.client_oname} lastName={pax.client_sname} phoneNumber={pax.phoneNumber ? pax.phoneNumber:""} key={pax.client_sname+ind} label={label} numero={ind+1}/>
                              </div>
                            )
                        })}
                        </div>
                    </div>}
                                   
                    {<div className='booking-resume'>
                       { quoteBookList.hasOwnProperty("Clients") && <h3 className='title-recap-booking'>2. Booking resume</h3> } 
                        <TableLayoutNew lists={quoteBookList} total={totalPrice}/>
                        <QuoteCancellationLayout data={{"reservationTransfer":quoteBookList.reservationTransfer,"reservationAccom":quoteBookList.reservationAccom}}/>
                    </div>}
                    
                    {showFooter() && <div className='save_quotaion_button'>
                    {
                        quoteBookList.refCode !== null ? 
                        null :  
                        <button  onClick={(e)=>{confirmSave(e)}} className={'finsavecotation '+(quoteBookList.refCode !== null ?"disabledCss2":"")}>Save Quotation</button>  
                    }
                    {/* <button onClick={(e)=>{Booking(e)}}  disabled={!!(quoteBookList.bookingId !== null)}   className={'finbooknow '+(quoteBookList.bookingId !== null ?"disabledCss2":"")}>Book Now</button>    */}
                    <button onClick={(e)=>{Booking(e)}}   className='finbooknow' >Book Now</button>   

                    </div> }    
                </>
                {isOpen ? getPopupContent():<></>}
            </div> 
        </div>
        </>
    );
}

export default SelectedViewDetails;